<template functional>
    <svg xmlns="http://www.w3.org/2000/svg" width="18.333" height="18.333" viewBox="0 0 18.333 18.333" class="fill-none stroke-danger">
    <g id="Icon" transform="translate(.456 1.665)">
        <path id="Path" d="M6.55 1.667h6.9l4.883 4.883v6.9l-4.883 4.883h-6.9L1.667 13.45v-6.9z" class="cls-1" transform="translate(-1.29 -2.499)"/>
        <path id="Line" d="M5 0L0 5" class="cls-1" transform="translate(6.21 5.001)"/>
        <path id="Line-2" d="M0 0L5 5" class="cls-1" transform="translate(6.21 5.001)"/>
    </g>
</svg>
</template>

<script>
export default {
    name: 'icon-error'
}
</script>

<style lang="scss" scoped>
    .cls-1 {
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 1.667px;
    }
</style>