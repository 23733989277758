<template>
    <form @submit.prevent="handleSubmit">
        <div class="grid grid-cols-2 md:grid-cols-5 gap-1.5">
            <algolia-menu attribute="vehicleMake" class="col-span-1 z-10">
                <algolia-search-dropdown
                    slot-scope="{ items, refine }"
                    :name="MAKE"
                    :algolia-items="items"
                    :options="makeItems"
                    :refine="refine"
                    v-model="filteredMake"
                >Make</algolia-search-dropdown>
            </algolia-menu>

            <algolia-menu attribute="vehicleModel" class="col-span-1 z-10">
                <algolia-search-dropdown
                    slot-scope="{ items, refine }"
                    :name="MODEL"
                    :is-enabled="isModelEnabled"
                    open-if-next-option
                    :algolia-items="items"
                    :options="modelItems"
                    :refine="refine"
                    v-model="filteredModel"
                >Model</algolia-search-dropdown>
            </algolia-menu>

            <algolia-menu attribute="vehicleYear" class="col-span-1">
                <algolia-search-dropdown
                    slot-scope="{ items, refine }"
                    :name="YEAR"
                    :is-enabled="isYearEnabled"
                    open-if-next-option
                    :algolia-items="items"
                    :options="yearItems"
                    :refine="refine"
                    v-model="filteredYear"
                >Year</algolia-search-dropdown>
            </algolia-menu>

            <algolia-menu attribute="categories.lvl0" class="col-span-1">
                <algolia-search-dropdown
                    slot-scope="{ items, refine }"
                    :name="PRODUCT"
                    :is-enabled="isProductEnabled"
                    open-if-next-option
                    :refine="refine"
                    :algolia-items="items"
                    :options="productItems"
                    v-model="filteredProduct"
                    @change="handleSubmitButtonFocus"
                >Category</algolia-search-dropdown>
            </algolia-menu>

            <button 
                type="submit" 
                class="btn col-span-2 md:col-span-1 inline-flex justify-center items-center px-1"
                ref="submitBtn"
            >
                <i class="fas fa-search mr-2.5"></i> Quick Find
            </button>
        </div>
    </form>
</template>

<script>
// Remember to wrap this component in <algolia-connect-find-it-fast> for it to work independently of the rest of the site. I haven't wrapped the component by default in case there is ever a use case where we want to include this form on the site and it be connected to the current algolia results.
import AlgoliaSearchDropdown from './AlgoliaSearchDropdown.vue'
import AlgoliaMenu from './AlgoliaMenu.vue'

import findItFastMixin from '../mixins/findItFastMixin'

export default {
    name: 'find-it-fast-form',
    mixins: [
        findItFastMixin
    ],
    components: {
        AlgoliaMenu,
        AlgoliaSearchDropdown
    },
    computed: {
        searchUrl() {
            return this.$store.state[this.moduleName].url
        }
    },
    methods: {
        handleSubmit() {
            if (this.searchUrl) {
                window.location = this.searchUrl
            }
        },
        handleSubmitButtonFocus(val) {
            if (val) this.$refs.submitBtn.focus()
        }
    }
};
</script>