/**
 * @param {Object} response - ajax response (axios)
 * @param {Function} cb - callback. If a callback is present and fails, the function will return falsy
 * @return {boolean} - Whether the response is valid and contains useable data
 */
export default function isResponseOk(response, cb) {
    if (
        !response.status || 
        response?.data === null || 
        response?.data?.error || 
        response.data === 'YII_LOGIN_REQUIRED' || 
        (typeof cb === 'function' && !cb(response))
    ) return false
    if (response.status >= 200 && response.status < 300) return true
    return false
}