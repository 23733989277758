<template>
    <form
        ref="form"
        method="GET"
        :action="`/${productsIndexPath}`"
        class="flex"
        :class="isFocused ? focusedClass : unfocusedClass"
    >
        <slot
            :searchInputName="searchInputName"
            :handleFocus="handleFocus"
            :handleBlur="handleBlur"
            :isLoading="isLoading"
            :isFocused="isFocused"
        >
            <input
                class="w-full px-2.5 py-2 md:px-5 md:py-2.5 bg-transparent placeholder-grey-dark focus:outline-none"
                type="search"
                :name="searchInputName"
                placeholder="Search for products"
                @focus="handleFocus"
                @blur="handleBlur"
            />
            <div class="relative w-14 flex-shrink-0 short-border short-border__left">
                <button
                    class="w-full h-full text-grey-blue hover:text-gray-950 focus:text-orange focus:outline-none bg-transparent"
                    :class="{ 'is-loading is-loading--small is-loading--orange': isLoading }"
                    type="submit"
                    title="Search"
                    @focus="handleFocus"
                    @blur="handleBlur"
                >
                    <i class="fas fa-search"></i>
                </button>
            </div>
        </slot>
    </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: "search",
    props: {
        focusedClass: {
            type: String,
            default: 'bg-white border border-gray-950'
        },
        unfocusedClass: {
            type: String,
            default: 'bg-grey-pale border border-grey-dark'
        }
    },
    data() {
        return {
            isFocused: false
        }
    },
    computed: {
        ...mapGetters('search', [
            'isLoading',
            'searchInputName'
        ]),
        ...mapGetters('settings', [
            'productsIndexPath'
        ])
    },
    methods: {
        handleFocus() {
            this.isFocused = true
        },
        handleBlur() {
            this.isFocused = false
        }
    }
};
</script>
