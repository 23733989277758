import Vue from 'vue'
import store from './store'
import lazyLoadComponent from '../helpers/lazyLoadComponent'

// Third party packages
import vClickOutside from 'v-click-outside'
import PortalVue from 'portal-vue'
import vueAwesomeCountdown from 'vue-awesome-countdown'

// Custom components
import Accordion from './components/Accordion'
import AccordionBox from './components/AccordionBox'
import AccordionGroup from './components/AccordionGroup'
import AccordionMenu from './components/AccordionMenu'
import AddedToCartNotification from './components/AddedToCartNotification'
import AddToCartButton from './components/AddToCartButton'
import AddToCartInputButton from './components/AddToCartInputButton'
import AjaxAddToCart from './components/AjaxAddToCart'
import AjaxDeleteItem from './components/AjaxDeleteItem'
import AlgoliaConnectFindItFast from './components/AlgoliaConnectFindItFast'
import Cards from './components/Cards'
import Cart from './components/Cart'
import CheckableRadio from './components/CheckableRadio'
import Checkbox from './components/Checkbox'
import Checkmark from './components/Checkmark'
// import DarkModeToggle from './components/DarkModeToggle' // TODO: Add this back in when the feature is ready
import ErrorList from './components/ErrorList'
import FindItFast from './components/FindItFast'
import FindItFastForm from './components/FindItFastForm'
import FlashNotification from './components/FlashNotification'
import FlashNotifications from './components/FlashNotifications'
import HierarchicalMenuList from './components/HierarchicalMenuList'
import ImgFeatured from './components/ImgFeatured'
import ImgPreview from './components/ImgPreview'
import InfoButtonWithLightbox from './components/InfoButtonWithLightbox'
import Lightbox from './components/Lightbox'
import LoadingIndicator from './components/LoadingIndicator'
import MegaMenuTrigger from './components/MegaMenuTrigger'
import MobileMenuToggle from './components/MobileMenuToggle'
import NavDropdown from './components/NavDropdown'
import Notification from './components/Notification'
import Notifications from './components/Notifications'
import Overlay from './components/Overlay'
import PasswordField from './components/PasswordField'
import PaymentMethods from './components/PaymentMethods'
import PriceHider from './components/PriceHider'
import ProductVariants from './components/ProductVariants'
import ProgressBar from './components/ProgressBar'
import ProgressBarOption from './components/ProgressBarOption'
import QtyInBasket from './components/QtyInBasket'
import Radio from './components/Radio'
import RewardPointsCheckbox from './components/RewardPointsCheckbox'
import Search from './components/Search'
import SetDefaultAddress from './components/SetDefaultAddress'
import SimpleAddToCartForm from './components/SimpleAddToCartForm'
import SkeletonResultsContainer from './components/SkeletonResultsContainer'
import SlideoutForm from './components/SlideoutForm'
import SlideoutFormObserver from './components/SlideoutFormObserver'
import SlideoutFormToggle from './components/SlideoutFormToggle'
import StockLevel from './components/StockLevel'
import Tab from './components/Tab'
import TabContent from './components/TabContent'
import Tabs from './components/Tabs'
import Toggle from './components/Toggle'

// Directives
import productPlaceholder from './directives/productPlaceholder'

// Vuex modules
import mobileMenu from './store/modules/mobileMenu/mobileMenu'
import megaMenu from './store/modules/megaMenu/megaMenu'

Vue.use(vClickOutside)
Vue.use(PortalVue)
Vue.use(vueAwesomeCountdown, 'countdown')

Vue.directive(productPlaceholder.name, productPlaceholder.object)

new Vue({
  el: '#app',
  store,
  components: {
    Accordion,
    AccordionBox,
    AccordionGroup,
    AccordionMenu,
    AddAddressButton: () => import(/* webpackChunkName: "add-address-button" */ './components/AddAddressButton'),
    AddCard: () => import(/* webpackChunkName: "add-card" */ './components/AddCard'),
    AdditionalEmails: () => import(/* webpackChunkName: "additional-emails" */ './components/AdditionalEmails.vue'),
    AddedToCartNotification, // lazy load?
    AddedToCartPopup: () => import(/* webpackChunkName: "added-to-cart-popup" */ './components/AddedToCartPopup'),
    AddressCard: () => import(/* webpackChunkName: "address-card" */ './components/AddressCard'),
    Addresses: () => import(/* webpackChunkName: "addresses" */ './components/Addresses'),
    AddressForm: () => import(/* webpackChunkName: "address-form" */ './components/AddressForm'),
    AddToCartButton,
    AddToCartInputButton,
    AjaxAddToCart,
    AjaxDeleteItem, // lazy load?
    AlgoliaConnectFindItFast, // lazy load?
    ArticleSectionTabs: () => import(/* webpackChunkName: "article-section-tabs" */ './components/ArticleSectionTabs'),
    ArticleSidebar: () => import(/* webpackChunkName: "article-sidebar" */ './components/ArticleSidebar'),
    BulkBuyConnector: () => import(/* webpackChunkName: "bulk-buy-connector" */ './components/BulkBuyConnector'),
    BulkBuyCta: () => import(/* webpackChunkName: "bulk-buy-cta" */ './components/BulkBuyCta'),
    BulkBuyInput: () => import(/* webpackChunkName: "bulk-buy-input" */ './components/BulkBuyInput'),
    BulkBuyPrice: () => import(/* webpackChunkName: "bulk-buy-price" */ './components/BulkBuyPrice'),
    BulkBuySpeechbox: () => import(/* webpackChunkName: "bulk-buy-speechbox" */ './components/BulkBuySpeechbox'),
    BulkBuySpeechboxActiveArea: () =>
      import(/* webpackChunkName: "bulk-buy-speechbox-active-area" */ './components/BulkBuySpeechboxActiveArea'),
    BulkBuyTable: () => import(/* webpackChunkName: "bulk-buy-table" */ './components/BulkBuyTable'),
    ByBoxDeliveryMethod: () => import(/* webpackChunkName: "by-box-delivery-method" */ './components/ByBoxDeliveryMethod'),
    Cards, // lazy load?
    Cart,
    CheckableRadio,
    Checkbox,
    Checkmark, // lazy load?
    Checkout: () => import(/* webpackChunkName: "checkout" */ './components/Checkout'),
    // DarkModeToggle, // TODO: Add this back in when the feature is ready
    DeliveryMethods: () => import(/* webpackChunkName: "delivery-methods" */ './components/DeliveryMethods'),
    ErrorList, // lazy load?
    FileUploader: () => import(/* webpackChunkName: "file-uploader" */ './components/FileUploader'),
    FinanceOptions: () => import(/* webpackChunkName: "finance-options" */ './components/FinanceOptions'),
    FindItFast,
    FindItFastForm, // lazy load?
    FlashNotification,
    FlashNotifications,
    FollowLinkConfirmation: () =>
      import(/* webpackChunkName: "follow-link-confirmation" */ './components/FollowLinkConfirmation'),
    Geolocation: () => import(/* webpackChunkName: "geolocation" */ './components/Geolocation'),
    HierarchicalMenuList, // lazy load?
    ImgFeatured, // lazy load?
    ImgPreview, // lazy load?
    InfoButtonWithLightbox, // lazy load?
    KioskCart: () => import(/* webpackChunkName: "kiosk-cart" */ './components/KioskCart'),
    KioskHomepage: () => import(/* webpackChunkName: "kiosk-homepage" */ './components/KioskHomepage'),
    KioskInactivityWrapper: () =>
      import(/* webpackChunkName: "kiosk-inactivity-wrapper" */ './components/KioskInactivityWrapper'),
    KioskInactivityPopup: () => import(/* webpackChunkName: "kiosk-inactivity-popup" */ './components/KioskInactivityPopup'),
    Lightbox,
    LoadingIndicator,
    LoginRequiredPopup: () => import(/* webpackChunkName: "login-required-popup" */ './components/LoginRequiredPopup'),
    Lookup: () => import(/* webpackChunkName: "lookup" */ './components/Lookup'),
    LookupForm: () => import(/* webpackChunkName: "lookup-form" */ './components/LookupForm'),
    LookupInput: () => import(/* webpackChunkName: "lookup-input" */ './components/LookupInput'),
    LookupVrmResults: () => import(/* webpackChunkName: "lookup-vrm-results" */ './components/LookupVrmResults'),
    MegaMenu: () => import(/* webpackChunkName: "mega-menu" */ './components/MegaMenu'),
    MegaMenuTrigger,
    MobileMenu: () => import(/* webpackChunkName: "mobile-menu" */ './components/MobileMenu'),
    MobileMenuToggle,
    NavDropdown,
    NewsletterForm: lazyLoadComponent({
      componentFactory: () => import(/* webpackChunkName: "newsletter-form" */ './components/NewsletterForm.vue'),
      loading: LoadingIndicator,
      loadingData: {
        attrs: {
          class: 'flex justify-center items-center'
        },
        props: {
          orange: false,
          size: 'large'
        }
      }
    }),
    Notification,
    Notifications,
    Overlay,
    PasswordField, // lazy load?
    PaymentMethods, // lazy load?
    PriceHider,
    PriceHiderToggle: lazyLoadComponent({
      componentFactory: () => import(/* webpackChunkName: "price-hider-toggle" */ './components/PriceHiderToggle.vue'),
      loading: LoadingIndicator
    }),
    ProductVariants, // lazy load?
    ProgressBar, // lazy load?
    ProgressBarOption, // lazy load?
    QuickBuy: () => import(/* webpackChunkName: "quick-buy" */ './components/QuickBuy'),
    QtyInBasket,
    Radio,
    ResultsContainer: () => import(/* webpackChunkName: "results-container" */ './components/ResultsContainer'),
    RewardPointsCheckbox, // lazy load?
    ScrollShadows: () => import(/* webpackChunkName: "scroll-shadows" */ './components/ScrollShadows'),
    Search,
    SetDefaultAddress, // lazy load?
    SimpleAddToCartForm,
    SkeletonResultsContainer,
    SlideoutForm, // lazy load?
    SlideoutFormObserver, // lazy load?
    SlideoutFormToggle, // lazy load?
    StockLevel,
    Tab,
    TabContent,
    Tabs,
    Toggle,
    Carousel: () => import(/* webpackChunkName: "carousel" */ './components/Carousel.vue'),
    VueSlickCarousel: () =>
      import(
        /* webpackChunkName: "vue-slick-carousel" */ '../../node_modules/vue-slick-carousel/dist/vue-slick-carousel.umd.min.js'
      )
  },
  created() {
    if (window?.App?.initialState) this.$store.dispatch('setInitialState', window.App.initialState)
    // Register the mobile/mega menu component and vuex module if appropriate (1024 is equal to tailwinds `lg` size)
    if (window.innerWidth < 1024) {
      if (!this.$store.hasModule('mobileMenu')) this.$store.registerModule('mobileMenu', mobileMenu)
      this.$store.dispatch('dynamicImport/load', 'mobileMenu')
    } else if (window.innerWidth > 1023) {
      if (!this.$store.hasModule('megaMenu')) this.$store.registerModule('megaMenu', megaMenu)
      this.$store.dispatch('dynamicImport/load', 'megaMenu')
    }
  }
})
