<template>
  <portal to="notifications">
    <transition name="slide">
      <div v-if="isActive" @mouseenter="handleMouseenter" @mouseleave="handleMouseleave">
        <button v-if="showClose" type="button"
          class="absolute top-0 right-0 w-8 h-8 text-white translate-x-1/2 -translate-y-1/2 rounded-full bg-grey-blue"
          @click.prevent="handleClose">
          <i class="fas fa-times"></i>
        </button>
        <slot></slot>
      </div>
    </transition>
  </portal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('notifications')

import notificationPropsMixin from '../mixins/notificationPropsMixin'

export default {
  name: 'notification',
  mixins: [notificationPropsMixin],
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    isActive() {
      return this.$store.getters['notifications/isActive'](this.id)
    }
  },
  methods: {
    ...mapActions([
      'addNotificationTimeout',
      'removeNotification',
      'removeNotificationTimeout',
      'setNotificationOptions'
    ]),
    handleMouseenter() {
      if (!this.resetTimeoutOnHover || !this.useTimeout) return
      this.removeNotificationTimeout(this.id)
    },
    handleMouseleave() {
      if (!this.resetTimeoutOnHover || !this.useTimeout) return
      this.addNotificationTimeout({
        id: this.id,
        timeout: this.timeout
      })
    },
    handleClose() {
      this.removeNotification(this.id)
    }
  },
  created() {
    const { id, useTimeout, timeout } = this
    this.setNotificationOptions({
      id,
      useTimeout,
      timeout
    })

    if (!this.showClose && !this.useTimeout) {
      console.warn('[Vue Notification Component Warning]: showClose and useTimeout are both set to false. This means that this notification will never be closed, either automatically or by the user. This could lead to a poor user experience.')
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-enter-active {
  transition: transform .5s cubic-bezier(.33, .95, .71, .94);
}

.slide-leave-active {
  transition: transform .5s cubic-bezier(.29, .06, .67, .05);
}

.slide-enter,
.slide-leave-to {
  transform: translateX(calc(100% + theme('spacing.8')));
}
</style>
