<template>
    <label class="inline-flex cursor-pointer">
        <checkbox name="redeemable" v-model="isUsingRewardPoints" value="1" class="mr-2.5 mt-0.5" />
        <div>
            <slot></slot>
        </div>
    </label>
</template>

<script>
import Checkbox from './Checkbox'

export default {
    name: 'reward-point-checkbox',
    components: {
        Checkbox
    },
    props: {
        initValue: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isUsingRewardPoints: {
            get() {
                return this.$store.getters['cart/isUsingRewardPoints']
            },
            set() {
                this.$store.dispatch('cart/toggleUsingRewardPoints')
            }
        }
    },
    created() {
        this.$store.dispatch('cart/setUsingRewardPoints', this.initValue)
    }
}
</script>