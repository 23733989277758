<template>
  <label :for="id" class="cursor-pointer">
    <slot></slot>
    <span
      class="toggle inline-flex justify-evenly items-center relative min-w-14 h-7.5 bg-grey-blue rounded-full cursor-pointer transition-color duration-300 ease-in-out"
      :class="[focusedClass, checkedClass]">
      <input type="checkbox"
        class="absolute inline-block w-6 h-6 transition-all duration-300 ease-in-out -translate-y-1/2 bg-white border-none rounded-full outline-none appearance-none toggle__button top-1/2"
        :name="name" :id="id" @keyup.enter.prevent="toggleIsChecked" @focus="toggleIsFocused" @blur="toggleIsFocused"
        v-model="isChecked">
      <span class="font-bold text-white uppercase transition-opacity duration-300 ease-in-out toggle__on-text text-xxs"
        :class="{ 'opacity-0': !isChecked, 'opacity-100': isChecked }">{{ onText }}</span>
      <span class="font-bold text-white uppercase transition-opacity duration-300 ease-in-out toggle__off-text text-xxs"
        :class="{ 'opacity-0': isChecked, 'opacity-100': !isChecked }">{{ offText }}</span>
    </span>
  </label>
</template>

<script>
export default {
  name: 'toggle',
  props: {
    name: {
      type: String,
      default: ''
    },
    id: {
      type: [String, Number],
      required: true
    },
    onText: {
      type: String,
      default: '',
      validator: function (text) {
        return text.length <= 3
      }
    },
    offText: {
      type: String,
      default: '',
      validator: function (text) {
        return text.length <= 3
      }
    },
    checkedByDefault: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isChecked: this.checkedByDefault,
      isFocused: false
    }
  },
  computed: {
    checkedClass() {
      return this.isChecked ? 'bg-orange' : ''
    },
    focusedClass() {
      if (this.isFocused && this.isChecked) {
        return 'bg-orange-dark'
      } else if (this.isFocused && !this.isChecked) {
        return 'bg-grey-dark'
      }
      return ''
    }
  },
  methods: {
    toggleIsChecked() {
      this.isChecked = !this.isChecked
    },
    toggleIsFocused() {
      this.isFocused = !this.isFocused
    }
  },
  watch: {
    isChecked(val) {
      this.$emit('toggled', val)
    }
  }
}
</script>

<style lang="scss" scoped>
$distanceFromEdge: 0.1875rem; // This is dependant on the height of the .toggle

.toggle {
  &__button {
    left: $distanceFromEdge;

    &:checked {
      left: calc(100% - (1.5rem + #{$distanceFromEdge}));
    }
  }
}
</style>
