<template>
    <img 
        class="cursor-pointer"
        :class="{ [activeClass]: isActive }"
        :style="hasError ? placeholderStyles : null"
        :src="!hasError ? imgSrc : placeholder" 
        :alt="altText" 
        @click="handleClick"
        @error="hasError = true"
    >
</template>

<script>
import { mapState } from 'vuex'
import { IMAGE_PLACEHOLDER } from '../store/constants'

export default {
    name: 'img-preview',
    props: {
        forModule: {
            type: String,
            required: true
        },
        imgSrc: {
            type: String,
            required: true
        },
        featuredImgSrc: {
            type: String,
            required: true
        },
        altText: {
            type: String,
            required: true
        },
        activeClass: {
            type: String,
            default: ''
        },
        placeholderStyles: {
            type: Object,
            default: () => ({ opacity: '0.4' })
        }
    },
    data() {
        return {
            placeholder: IMAGE_PLACEHOLDER,
            hasError: false
        }
    },
    computed: {
        ...mapState({
            moduleName(state) {
                return state.modulePrefixes.featuredImage + this.forModule
            }
        }),
        isActive() {
            return this.$store.getters[`${this.moduleName}/isActive`](this.featuredImgSrc)
        }
    },
    methods: {
        handleClick() {
            if (this.$store.hasModule(this.moduleName)) {
                this.$store.dispatch(`${this.moduleName}/setImage`, { src: this.featuredImgSrc, alt: this.altText })
            }
        }
    }
}
</script>