<template functional>
  <div :class="[data.staticClass, data.class]">
    <div class="flex flex-col lg:flex-row lg:items-start">
      <!-- Sidebar -->
      <aside class="hidden lg:block flex-shrink-0 w-60 bg-white shadow-box-small p-5 mr-4 mb-4 space-y-5">
        <div>
          <h3 class="heading heading--grey-blue font-bold leading-none">Filter</h3>
          <span class="block bg-grey-pale w-17 h-4 mt-2.5 -mb-0.5"></span>
        </div>
        <div>
          <span class="block bg-grey-pale w-full h-10"></span>
        </div>
        <div class="space-y-1.5">
          <span v-for="n in 10" :key="n" class="block bg-grey-pale w-full h-6.5"></span>
        </div>
        <div class="grid grid-cols-2 gap-1.5">
          <span v-for="n in 10" :key="n" class="col-span-1 bg-grey-pale h-6.5"></span>
        </div>
      </aside>

      <main class="grow sm:bg-white pb-4 sm:p-4">
        <!-- Sort Bar -->
        <div class="flex justify-between pb-4.5 lg:border-b-2 border-grey-blue relative z-10">
          <div class="flex sm:grid sm:grid-cols-2 sm:gap-4.5">
            <span class="block bg-grey-pale w-24 sm:w-36 h-9 sm:h-10 mr-2 sm:mr-0"></span>
            <span class="block bg-grey-pale w-24 sm:w-36 h-9 sm:h-10"></span>
          </div>
          <div class="flex">
            <span class="block bg-grey-pale w-8.5 sm:w-22 h-9 sm:h-10 mr-2.5 sm:mr-3.5"></span>
            <span class="block bg-grey-pale w-8.5 sm:w-22 h-9 sm:h-10"></span>
          </div>
        </div>

        <!-- Mobile Filters -->
        <div class="lg:hidden bg-grey-pale h-12 mb-6.5"></div>

        <!-- Stats -->
        <div class="lg:hidden flex justify-between mb-2.5">
          <span class="bg-grey-pale w-20 h-6"></span>
          <span class="bg-grey-pale w-22 h-6"></span>
        </div>

        <!-- Results -->
        <div
          class="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-2 gap-y-2 sm:gap-x-4 sm:gap-y-4 sm:gap-x-6.5 sm:gap-y-11 lg:pt-4 mb-6.5 sm:mb-22">
          <div v-for="n in 12" :key="n">
            <div class="border-2 border-grey-pale h-50"></div>
            <span class="block bg-grey-pale w-full h-11.5 mt-5"></span>
            <span class="block bg-grey-pale w-8 h-6 mt-3.5"></span>
            <span class="block bg-grey-pale w-35 h-4 mt-2.5"></span>
            <span class="block bg-grey-pale w-13 h-6 mt-2.5"></span>
            <div class="flex space-x-1 sm:space-x-1.5 mt-6">
              <span
                class="flex justify-center items-center text-center text-grey-pale border border-grey-pale w-12.5">1</span>
              <span class="block bg-grey-pale grow h-7"></span>
            </div>
          </div>
        </div>

        <!-- Pagination -->
        <div class="flex justify-center items-center space-x-1">
          <span class="block bg-grey-pale w-10.5 h-8"></span>
          <span class="block bg-grey-pale w-10.5 h-8"></span>
          <span>...</span>
          <span class="block bg-grey-pale w-10.5 h-8"></span>
          <span class="block bg-grey-pale w-10.5 h-8"></span>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'skeleton-results-container'
}
</script>
