<template>
    <ais-instant-search
        v-if="shouldMount"
        :index-name="indexName"
        :search-client="searchClient"
        :stalled-search-delay="500"
        :search-function="customSearchFunction"
    >
        <ais-configure :filters="algoliaDefaultFilters" />
        <find-it-fast-form :module-name="findItFastModule">
            <slot></slot>
        </find-it-fast-form>
    </ais-instant-search>
</template>

<script>
import { AisInstantSearch, AisConfigure } from 'vue-instantsearch'
import FindItFastForm from './FindItFastForm'
import { history as historyRouter } from 'instantsearch.js/es/lib/routers';
const router = historyRouter()
import { mapGetters, mapState } from 'vuex';
import algoliasearch from 'algoliasearch/lite'

export default {
    name: "algolia-connect-find-it-fast",
    components: {
        AisInstantSearch,
        AisConfigure,
        FindItFastForm
    },
    props: {
        findItFastModule: {
            type: String,
            default: 'findItFast'
        },
        screenWidthToMount: {
            type: Number,
            default: 300
        }
    },
    data() {
        return {
            shouldMount: false,
            customSearchFunction: helper => {
                if (helper.state) {
                    const refinements = helper.state.hierarchicalFacetsRefinements
                    if (refinements && this.facets.every(facet => refinements.hasOwnProperty(facet))) {
                        this.facets.forEach(facet => {
                            if (facet === this.categoryFacet) {
                                const hMenu = this.uiState[this.indexName].hierarchicalMenu
                                if (refinements[facet].length > 0) {
                                    hMenu[facet] = [refinements[facet][0]]
                                } else if (hMenu.hasOwnProperty(facet)) {
                                    delete hMenu[facet]
                                }
                            } else {
                                const menu = this.uiState[this.indexName].menu
                                if (refinements[facet].length > 0) {
                                    menu[facet] = refinements[facet][0]
                                } else if (menu.hasOwnProperty(facet)) {
                                    delete menu[facet]
                                }
                            }
                        })

                        let searchUrl = router.createURL(this.uiState)
                        if (searchUrl.indexOf(`/${this.productsPath}`) === -1) {
                            const splitSearchUrl = searchUrl.split('?')
                            splitSearchUrl.splice(0, 1, `${window.origin}/${this.productsPath}`)
                            searchUrl = splitSearchUrl.join('?')
                        }
                        this.$store.dispatch(`${this.findItFastModule}/setUrl`, searchUrl)
                    }
                    helper.search()
                }
            }
        }
    },
    computed: {
        ...mapState('settings', {
            indexName: state => state.algoliaIndexName,
            productsPath: state => state.productsIndexPath
        }),
        ...mapGetters('settings', [
            'algoliaAppId',
            'algoliaApiKey',
            'algoliaDefaultFilters'
        ])
    },
    methods: {
        setShouldMount(event) {
            this.shouldMount = event.matches
        }
    },
    created() {
        this.makeFacet = 'vehicleMake'
        this.modelFacet = 'vehicleModel'
        this.yearFacet = 'vehicleYear'
        this.categoryFacet = 'categories.lvl0'
        this.facets = [this.makeFacet, this.modelFacet, this.yearFacet, this.categoryFacet]
        this.uiState = {
            [this.indexName]: {
                menu: {},
                hierarchicalMenu: {}
            }
        }
    },
    mounted() {
        const mql = window.matchMedia(`screen and (min-width: ${this.screenWidthToMount}px)`)
        this.shouldMount = mql.matches
        try {
            mql.addEventListener('change', this.setShouldMount)
        } catch(error) {
            try {
                mql.addListener(this.setShouldMount)
            } catch(error) {
                console.error(error)
            }
        }

        if (this.algoliaAppId && this.algoliaApiKey) {
            this.searchClient = algoliasearch(this.algoliaAppId, this.algoliaApiKey, { _useRequestCache: true })
        }
    }
}
</script>
