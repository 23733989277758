import {
    SET_LAYOUT_MODE
} from './_constants'

const lsKey = 'layoutMode'

const state = () => ({
    activeMode: localStorage.getItem(lsKey) ?? 'grid'
})

const getters = {
    isActive: state => mode => state.activeMode === mode
}

const mutations = {
    [SET_LAYOUT_MODE](state, { mode }) {
        state.activeMode = mode
        localStorage.setItem(lsKey, mode)
    }
}

const actions = {
    setLayoutMode({ commit }, payload) {
        commit(SET_LAYOUT_MODE, payload)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}