<template functional>
    <svg xmlns="http://www.w3.org/2000/svg" width="18.9" height="18.626" viewBox="0 0 18.9 18.626"
        class="fill-none stroke-green-700">
        <g id="Icon" transform="translate(.679 1.849)">
            <path id="Path" d="M18.333 9.233V10a8.333 8.333 0 1 1-4.941-7.617" class="cls-1"
                transform="translate(-1.29 -2.499)" />
            <path id="Path-2" d="M18.333 3.333L10 11.675l-2.5-2.5" class="cls-1" transform="translate(-1.29 -2.499)" />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'icon-success'
}
</script>

<style lang="scss" scoped>
.cls-1 {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.667px;
}
</style>
