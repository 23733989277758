export default {
    props: {
        useTimeout: {
            type: Boolean,
            default: true
        },
        timeout: {
            type: Number,
            default: 10000 // ms
        },
        resetTimeoutOnHover: {
            type: Boolean,
            default: true
        },
        showClose: {
            type: Boolean,
            default: true
        }
    }
}