<template>
    <label :for="id ? id : null" class="inline-block cursor-pointer">
        <!-- If component is using v-model -->
        <input 
            v-if="isUsingVModel"
            type="radio"
            class="appearance-none hidden"
            :id="id ? id : null"
            :name="name ? name : null" 
            :value="value ? value : null"
            :checked="isChecked"
            @change="handleChange"
        >
        <!-- If using the component without v-model (as a standard radio input for example) -->
        <input
            v-else
            type="radio"
            class="appearance-none hidden"
            :id="id ? id : null"
            :name="name ? name : null" 
            :value="value ? value : null"
        >
        <!-- 'Checked' styles being set in the <style> tag below -->
        <i class="fas fa-check text-grey-pale"></i>
    </label>
</template>

<script>
export default {
    name: 'checkmark',
    props: {
        id: {
            type: String
        },
        name: {
            type: String
        },
        value: {
            type: String,
            required: true
        },
        modelValue: {}
    },
    model: {
        prop: 'modelValue',
        event: 'change'
    },
    computed: {
        isUsingVModel() {
            return typeof this.modelValue !== 'undefined' ? true : false
        },
        isChecked() {
            return this.modelValue == this.value
        }
    },
    methods: {
        handleChange() {
            this.$emit('change', this.value)
        }
    }
}
</script>

<style lang="scss" scoped>
input {
    &:checked {
        & + i {
            @apply text-orange;
        }
    }
}
</style>