import {
    LOAD
} from './_constants'

const state = () => ({
    addedToCartPopup: false,
    loginRequiredPopup: false,
    mobileMenu: false,
    megaMenu: false
})

const getters = {
    addedToCartPopup: state => state.addedToCartPopup,
    loginRequiredPopup: state => state.loginRequiredPopup,
    mobileMenu: state => state.mobileMenu,
    megaMenu: state => state.megaMenu
}

const mutations = {
    [LOAD](state, component) {
        state[component] = true
    }
}

const actions = {
    load({ commit }, component) {
        commit(LOAD, component)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}