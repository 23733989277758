<template>
    <component :is="tag">
        <slot></slot>
    </component>
</template>

<script>
import { mapState } from 'vuex'
import accordionModule from '../store/modules/accordion/accordion'
const { state, getters, mutations, actions } = accordionModule

export default {
    name: 'accordion-group',
    provide() {
        return {
            accordionGroupModule: this.moduleName
        }
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        tag: {
            type: String,
            default: 'div'
        }
    },
    data() {
        return {
            moduleID: this.name ? this.name : this._uid
        }
    },
    computed: {
        ...mapState({
            moduleName(state) {
                return state.modulePrefixes.accordion + this.moduleID
            }
        })
    },
    created() {
        if (!this.$store.hasModule(this.moduleName)) {
            this.$store.registerModule(this.moduleName, {
                namespaced: true,
                state,
                getters,
                mutations,
                actions
            })
        }
    }
}
</script>