<template>
    <select v-if="variants" v-model="selectedVariant">
        <option default disabled value="">PRODUCT VARIATION</option>
        <option v-for="variant in variants" :key="variant.productID" :value="variant.SKU">{{ variant.variant_name }}</option>
    </select>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: "product-variants",
    props: {
        variants: {
            type: Array, // Array<{ SKU: string; productID: string; variant_name: string; }>
            default: () => []
        },
        currentProductId: {
            type: String,
            required: true
        }
    },
    mounted() {
        const foundVariant = this.variants.find(variant => variant.productID === this.currentProductId)
        if (foundVariant) {
            this.selectedVariant = foundVariant.SKU
        }
    },
    data() {
        return {
            selectedVariant: ''
        }
    },
    computed: {
        ...mapState('settings', [ 'productPath' ])
    },
    watch: {
        selectedVariant(sku) {
            const variant = this.variants.find(variant => variant.SKU === sku)
            if (variant.productID !== this.currentProductId) {
                window.location = `/${this.productPath}/${sku}`
            }
        }
    }
};
</script>