import {
    SET_IMAGE
} from './_constants'

const state = () => ({
    src: '',
    alt: ''
})

const getters = {
    src: state => state.src,
    alt: state => state.alt,
    isActive: state => src => state.src === src
}

const mutations = {
    [SET_IMAGE](state, { src, alt }) {
        state.src = src
        state.alt = alt
    },
}

const actions = {
    setImage({ commit }, payload) {
        commit(SET_IMAGE, payload)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}