<template>
  <accordion class="border-2 group border-grey-blue hover:border-grey-dark"
    active-class="border-orange hover:border-orange" :tag="tag" :duration="duration" :default-active="defaultActive">
    <template slot="title" slot-scope="{ isOpen, transitionDuration }">
      <span
        class="flex justify-between items-center text-white uppercase font-bold leading-tight bg-grey-blue px-4.5 py-3"
        :class="{ 'bg-orange': isOpen, 'group-hover:bg-grey-dark': !isOpen }">
        <slot name="title">Set Title Slot</slot>
        <i class="rotate-0 fas fa-chevron-down" :class="[transitionDuration, { '-rotate-180': isOpen }]"></i>
      </span>
    </template>

    <slot></slot>
  </accordion>
</template>

<script>
import Accordion from './Accordion'

export default {
  name: 'accordion-box',
  components: {
    Accordion
  },
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    duration: {
      type: [String, Number],
      default: 300
    },
    defaultActive: {
      type: Boolean,
      default: false
    }
  },
}
</script>
