<template>
    <div>
        <component v-for="method in paymentMethods" :key="method.type" :is="method.type" :method-data="method" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FinancePaymentType from './FinancePaymentType'
import OffsitePaymentType from './OffsitePaymentType'
import CardPaymentType from './CardPaymentType'
import CreditPaymentType from './CreditPaymentType'

export default {
    name: 'payment-methods',
    components: {
        FinancePaymentType,
        OffsitePaymentType,
        CardPaymentType,
        CreditPaymentType
    },
    props: {
        addCardSlideoutFormId: {
            type: String
        },
        addressData: {
            type: Array,
            default: () => []
        },
        countries: {
            type: Object,
            required: true
        }
    },
    provide() {
        return {
            addCardFormId: this.addCardSlideoutFormId,
            addressData: this.addressData,
            countries: this.countries
        }
    },
    computed: {
        ...mapGetters('checkout', [
            'paymentMethods'
        ])
    }
}
</script>