<template>
    <payment-type v-if="hasOptions" :heading="methodData.heading" :type="methodData.type">
        <div class="payment-method">
            <label
                v-for="({ id, name }) in methodData.options"
                :key="id"
                class="payment-method__option"
                :class="{ 'payment-method__option--selected': isSelected(id) }"
            >
                <h3 class="payment-method__name">{{ name }}</h3>
                <checkable-radio v-model="activePaymentMethodId" :value="id" size="sm">Select</checkable-radio>
            </label>
        </div>
    </payment-type>
</template>

<script>
import PaymentType from './PaymentType'
import CheckableRadio from './CheckableRadio'

import paymentMethod from '../mixins/paymentMethod'

export default {
    name: 'offsite-payment-type',
    mixins: [paymentMethod],
    components: {
        PaymentType,
        CheckableRadio
    },
    props: {
        methodData: {
            type: Object,
            require: true
        }
    }
}
</script>
