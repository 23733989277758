<template>
    <notification :id="notificationId" :useTimeout="useTimeout" :timeout="calculatedTimeout"
        :resetTimeoutOnHover="resetTimeoutOnHover" :showClose="showClose">
        <div class="border-2 border-gray-950 px-2.5 py-1.5 shadow-box-small font-bold w-58" :class="flashData.css">
            <h4 class="border-b-2 border-current uppercase mb-1.5 flex items-center">
                <component :is="flashData.icon" />
                <span class="ml-2">{{ flashData.heading }}</span>
            </h4>
            <div class="text-sm">
                <slot></slot>
            </div>
        </div>
    </notification>
</template>

<script>
import Notification from './Notification'
import IconError from './IconError'
import IconSuccess from './IconSuccess'
import IconInfo from './IconInfo'
import IconWarning from './IconWarning'

import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('notifications')

import notificationPropsMixin from '../mixins/notificationPropsMixin'

export default {
    name: 'flash-notification',
    mixins: [notificationPropsMixin],
    components: {
        Notification,
        IconError,
        IconSuccess,
        IconInfo,
        IconWarning
    },
    props: {
        id: {
            type: [String, Number],
        },
        type: {
            type: String,
            default: ''
        },
        calculateTimeout: {
            type: Boolean,
            default: true // Setting this to false will mean that regardless of the word count, the timeout being passed through will be used
        }
    },
    computed: {
        notificationId() {
            return this.id ? this.id : this._uid
        },
        isActive() {
            return this.$store.getters['notifications/isActive'](this.notificationId)
        },
        flashData() {
            const data = {
                heading: 'System Information',
                css: 'bg-blue-light text-blue-dark',
                icon: 'icon-info'
            }
            switch (this.type) {
                case 'error':
                    data.heading = 'Error'
                    data.css = 'bg-red-100 text-red-900'
                    data.icon = 'icon-error'
                    return data
                case 'success':
                    data.heading = 'Success'
                    data.css = 'bg-mint text-green-700'
                    data.icon = 'icon-success'
                    return data
                case 'warning':
                    data.heading = 'Warning'
                    data.css = 'bg-yellow-light text-olive-dark'
                    data.icon = 'icon-warning'
                    return data
                default:
                    return data
            }
        },
        calculatedTimeout() {
            const message = this.$slots.default && this.$slots.default[0].text
            if (!this.calculateTimeout || !message) return this.timeout

            const wordCount = message.split(' ').length
            return wordCount > 10 ? wordCount * 1000 : this.timeout
        }
    },
    methods: {
        ...mapActions([
            'showNotification'
        ])
    },
    mounted() {
        this.showNotification(this.notificationId)
    },
    watch: {
        isActive(newVal, oldVal) {
            // If the new value is false and the old value is true, then the notification has just closed
            if (!newVal && oldVal) {
                this.$emit('closed', { id: this.notificationId })
            }
        }
    }
}
</script>
