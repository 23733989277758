import {
    SHOW_ADDED_TO_CART_POPUP,
    CLOSE_ADDED_TO_CART_POPUP
} from './_constants'

const state = () => ({
    isActive: false,
    productId: null
})

const getters = {
    isActive: state => state.isActive,
    productId: state => state.productId
}

const mutations = {
    [SHOW_ADDED_TO_CART_POPUP](state, productId) {
        state.isActive = true
        state.productId = productId
    },
    [CLOSE_ADDED_TO_CART_POPUP](state) {
        state.isActive = false
        state.productId = null
    }
}

const actions = {
    showPopup({ commit, dispatch }, productId) {
        commit(SHOW_ADDED_TO_CART_POPUP, productId)
        dispatch('dynamicImport/load', 'addedToCartPopup', { root: true })
    },
    closePopup({ commit }) {
        commit(CLOSE_ADDED_TO_CART_POPUP)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}