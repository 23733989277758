<template>
    <component :is="tag" :class="{ [activeClass]: isOpen }">
        <button
            type="button" 
            class="w-full text-left appearance-none"
            @click="handleClick"
        >
            <slot name="title" :isOpen="isOpen" :transitionDuration="transitionDuration"></slot>
        </button>

        <div 
            class="overflow-hidden h-full max-h-0 transition-all ease-in-out" 
            :class="transitionDuration"
            ref="content"
        >
            <slot :recalculateHeight="recalculateHeight"></slot>
        </div>
    </component>
</template>

<script>
export default {
    name: 'accordion',
    inject: {
        accordionGroupModule: { default: null }
    },
    props: {
        duration: {
            type: [String, Number],
            default: 300
        },
        activeClass: {
            type: String,
            default: ''
        },
        tag: {
            type: String,
            default: 'div'
        },
        defaultActive: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        if (this.defaultActive) {
            this.openAccordion()
            this.setOpenAccordion(this._uid)
        }
    },
    data() {
        return {
            isOpen: false
        }
    },
    computed: {
        idOfOpenAccordionInGroup() {
            return this.accordionGroupModule ? this.$store.getters[`${this.accordionGroupModule}/idOfOpenAccordion`] : null
        },
        transitionDuration() {
            return 'duration-' + this.duration
        },
        resizeObserver() {
            return new ResizeObserver(this.recalculateHeight)
        }
    },
    watch: {
        idOfOpenAccordionInGroup(openId) {
            if (openId !== this._uid) {
                this.closeAccordion()
            }
        },
        isOpen(val) {
            if (val) {
                this.$emit('opened')
            } else {
                this.$emit('closed')
            }
        }
    },
    methods: {
        handleClick() {
            if (this.isOpen) {
                this.closeAccordion()
                this.setOpenAccordion(null)
            } else {
                this.openAccordion()
                this.setOpenAccordion(this._uid)
            }
        },
        openAccordion() {
            this.calculateHeight()
            this.isOpen = true
            this.useResizeObserver()
        },
        closeAccordion() {
            this.removeResizeObserver()
            this.$refs.content.style.maxHeight = null
            this.isOpen = false
        },
        setOpenAccordion(id = null) {
            if (this.accordionGroupModule) {
                this.$store.dispatch(`${this.accordionGroupModule}/setOpenAccordion`, { id })
            }
        },
        calculateHeight() {
            if (this.$refs.content) {
                this.$refs.content.style.maxHeight = this.$refs.content.scrollHeight + 'px'
            }
        },
        recalculateHeight() {
            this.$nextTick(() => {
                this.calculateHeight()
            })
        },
        useResizeObserver() {
            this.resizeObserver.observe(this.$refs.content.firstElementChild)
        },
        removeResizeObserver() {
            this.resizeObserver.unobserve(this.$refs.content.firstElementChild)
        }
    }
}
</script>