<template>
    <div>
        <!-- Button -->
        <button 
            @click.prevent="handleClick" 
            class="btn btn--grey h-full flex justify-center items-center text-xxxs px-2 py-1"
            :class="isPending && 'is-loading is-loading--small'"
        >
            <span class="rounded-full border border-white w-5 h-5 flex justify-center items-center" :class="isPending && 'border-none'"><i class="fas fa-info"></i></span>
        </button>

        <!-- Lightbox -->
        <info-lightbox 
            v-if="showInfo && info" 
            :info="info"
            @close="hideInfo"
        >
            <a :href="productUrl" class="flex pt-4 mb-4">
                <figure class="w-30 h-25 flex-shrink-0 flex justify-center items-center border-2 border-gray-200 p-2 mr-3">
                    <img class="max-h-full" :src="productImage" :alt="`Image for ${productTitle}`">
                </figure>
                <h2 class="text-lg leading-tight">{{ productTitle }}</h2>
            </a>
        </info-lightbox>
    </div>
</template>

<script>
import InfoLightbox from './InfoLightbox'

import apiClient from '../../xhr/apiClient'
import isResponseOk from '../../helpers/isResponseOk'

export default {
    name: 'info-button-with-lightbox',
    components: {
        InfoLightbox
    },
    props: {
        productId: {
            type: Number,
            required: true
        },
        productTitle: {
            type: String,
            required: true
        },
        productImage: {
            type: String,
            required: true
        },
        productUrl: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            showInfo: false,
            isPending: false,
            info: null // Once set this can be: string | Array<{ brandID: string; from: string; id: string; modelID: string; notes: string; productID: string; to: string; trimID: string | null }>
        }
    },
    methods: {
        hideInfo() {
            this.showInfo = false
        },
        async handleClick() {
            // note: info === application list array || description
            // 1. If we already have a info in memory, show the popup
            // 2. If we have no info in memory, fetch it
            if (this.info) return this.showInfo = true

            this.isPending = true
            try {
                const response = await apiClient.get('/product/application', {
                    params: {
                        id: this.productId,
                        json: true
                    }
                })
                if (!isResponseOk(response)) throw new Error()
                const { applicationList, description } = response.data
                if (applicationList.length > 0) {
                    this.info = applicationList
                } else if (description.length > 0) {
                    this.info = description
                }
                this.showInfo = this.info ? true : false
            } catch (error) {
                // We need a better response from the server to determine how to handle the error. For now, the most likely case is that the user is not logged in.
                this.$store.dispatch('auth/showLoginPopup', 'You must be logged in to view the application list/description.')
            }
            this.isPending = false
        }
    }
}
</script>