<template>
    <span
        class="text-xs text-center text-white font-bold uppercase leading-really-tight bg-orange px-2 py-0.5"
        v-if="qtyInCart(productId)"
        v-text="`${qtyInCart(productId)} in Basket`"
    ></span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'qty-in-basket',
    props: {
        productId: {
            type: Number,
            required: true
        }
    },
    computed: {
        ...mapGetters('cart', [
            'qtyInCart'
        ])
    }
}
</script>