<template>
  <div class="relative" v-click-outside="vcoConfig">
    <div class="h-full border-2 nav-dropdown-trigger" :class="isOpen ? activeClassTrigger : 'border-transparent'">
      <slot name="trigger" :toggle="toggle" :isOpen="isOpen"></slot>
    </div>
    <div v-show="isOpen" class="absolute right-0 top-full min-w-48" :class="activeClass">
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'nav-dropdown',
  data() {
    return {
      isOpen: false,
      activeClass: 'border-2 border-gray-950 bg-white shadow-box',
      vcoConfig: {
        handler: this.toggle,
        middleware: this.clickOutsideMiddleware
      }
    }
  },
  computed: {
    activeClassTrigger() {
      return `${this.activeClass} is-active`
    }
  },
  methods: {
    clickOutsideMiddleware() {
      return this.isOpen ? true : false
    },
    toggle() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-dropdown-trigger {
  position: relative;

  &.is-active {
    &:after {
      content: '';
      width: 100%;
      height: 4px;
      position: absolute;
      left: 0;
      top: 100%;
      background-color: white;
      z-index: 10;
    }
  }
}
</style>
