<template>
  <lightbox @click-outside="closeLightbox">
    <div class="relative flex flex-col w-full max-w-sm max-h-full mx-auto bg-white border-2 rounded border-orange"
      :class="lightboxMaxWidth">
      <!-- Close button -->
      <lightbox-close-button @click.prevent="closeLightbox"
        class="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2"></lightbox-close-button>
      <div class="h-full overflow-scroll p-2.5 sm:p-7.5 hide-scrollbar">
        <slot></slot>
        <ul v-if="isApplicationList && info.length > 0" class="alt-colour-list alt-colour-list--sm">
          <li v-for="item in info" :key="item.id" class="flex justify-between">
            <span class="mr-2">{{ item.brand }} {{ item.model }}</span>
            <span>{{ formattedYears(item) }}</span>
          </li>
        </ul>
        <div v-else-if="isDescription && info.length > 0" v-html="info" class="wysiwyg"></div>
        <p v-else>No info to show.</p>
      </div>
    </div>
  </lightbox>
</template>

<script>
import Lightbox from './Lightbox'
import LightboxCloseButton from './LightboxCloseButton'

export default {
  name: 'info-lightbox',
  components: { Lightbox, LightboxCloseButton },
  props: {
    info: {
      // string | Array<{ brandID: string; from: string; id: string; modelID: string; notes: string; productID: string; to: string; trimID: string | null }>
      type: [Array, String],
      required: true
    }
  },
  computed: {
    isApplicationList() {
      return Array.isArray(this.info)
    },
    isDescription() {
      return typeof this.info === 'string'
    },
    lightboxMaxWidth() {
      switch (true) {
        case this.isApplicationList:
          return 'max-w-sm'
        case this.isDescription:
          return 'max-w-lg'
        default:
          return 'max-w-lg'
      }
    }
  },
  methods: {
    closeLightbox() {
      this.$emit('close')
    },
    formattedYears({ fromYear, toYear }) {
      let output = '';

      if (fromYear) {
        output = fromYear
      }

      if (toYear && fromYear !== toYear) {
        output = output ? `${output} - ${toYear}` : toYear
      }

      return output;
    }
  }
}
</script>
