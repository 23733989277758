<template>
    <div class="border border-grey-blue" :class="{ 'border-danger': hasError, 'border-orange': isSelected }">
        <button @click.prevent="updatePaymentMethod({ paymentMethod: type })" class="w-full bg-white flex justify-between items-center pl-4.5 pr-2.75 py-2.75 cursor-pointer">
            <span class="pr-4 font-bold text-left text-grey-blue" :class="{ 'text-danger': hasError, 'text-orange': isSelected }">{{ heading }}</span>
            <checkmark v-model="activePaymentMethod" :value="type" class="text-xl"></checkmark>
        </button>

        <div v-if="isSelected" class="bg-white pl-4.5 pr-2.75 pb-4.5">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import Checkmark from './Checkmark'

import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'payment-type',
    components: {
        Checkmark
    },
    props: {
        heading: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        hasError: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters('checkout', [
            'activePaymentMethod'
        ]),
        isSelected() {
            return this.activePaymentMethod === this.type
        }
    },
    methods: {
        ...mapActions('checkout', [
            'updatePaymentMethod'
        ])
    }
}
</script>
