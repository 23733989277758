<template>
    <payment-type :heading="methodData.heading" :type="methodData.type">
        <cards 
            class="payment-method" 
            :init-cards="cardData" 
        >
            <template slot-scope="{ cards }">
                <template v-if="cards.length">
                    <component 
                        v-for="card in cards" 
                        :key="card.id" 
                        :is="card.isExpired ? 'div' : 'label'"
                        class="col-span-full sm:col-span-3 xl:col-span-2 border border-grey-blue text-grey-blue px-3 py-2"
                        :class="{ 'border-orange text-orange': isSelected(card.id), 'cursor-pointer': !card.isExpired }"
                    >
                        <div class="flex items-center border-b-2 border-grey-blue pb-2 mb-2" :class="{ 'border-orange': isSelected(card.id) }">
                            <span class="flex-shrink-0 text-2xl mr-4"><i class="far fa-credit-card"></i></span>
                            <h3 class="font-bold uppercase">XXXX-XXXX-XXXX-{{ card.card }}</h3>
                        </div>
                        <div class="flex justify-between items-end">
                            <div class="flex flex-col text-xs">
                                <span class="font-bold">{{ card.customer_name }}</span>
                                <span>{{ card.expire }} <span v-if="card.isExpired" class="text-danger">Expired</span></span>
                            </div>

                            <checkable-radio v-if="!card.isExpired" v-model="activePaymentMethodId" :value="card.id" size="sm">Select</checkable-radio>
                            <template v-else>
                                <button type="button" class="btn btn--delete" @click="handleShowDeleteCardConfirmation(card)">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </template>
                        </div>
                    </component>
                </template>

                <add-card
                    v-if="addCardFormId"
                    class="col-span-full sm:col-span-3 xl:col-span-1 border border-grey-blue"
                    btn-class="p-4 w-full h-full"
                    :slideout-form-id="addCardFormId"
                    :address-data="addressData"
                    :countries="countries"
                >
                    <span class="flex flex-col justify-center items-center h-full text-center text-grey-blue">
                        <i class="fas fa-plus text-xl"></i>
                        <span class="text-sm">Add Card</span>
                    </span>
                </add-card>
            </template>
        </cards>

        <!-- Delete Card Confirmation -->
        <confirm-lightbox
            v-if="showDeleteCardConfirmation"
            @confirm="handleDeleteCard"
            @cancel="handleCancelDeleteCard"
            container-class="max-w-96"
        >
            <template slot="heading-text">Are you sure you want to delete this bank card?</template>
            <p>Please confirm that you would like to delete the card ending in <strong>{{ cardToDelete.card }}</strong></p>
        </confirm-lightbox>
    </payment-type>
</template>

<script>
import Cards from './Cards.vue'
import CheckableRadio from './CheckableRadio.vue'
import PaymentType from './PaymentType.vue'

import paymentMethod from '../mixins/paymentMethod'

import apiClient from '../../xhr/apiClient'
import isResponseOk from '../../helpers/isResponseOk'

export default {
    name: 'card-payment-type',
    mixins: [paymentMethod],
    inject: {
        addCardFormId: { default: null },
        addressData: { default: [] },
        countries: { default: {} }
    },
    components: {
        AddCard: () => import(/* webpackChunkName: "add-card" */'./AddCard.vue'),
        Cards,
        CheckableRadio,
        ConfirmLightbox: () => import(/* webpackChunkName: "confirm-lightbox" */'./ConfirmLightbox.vue'),
        PaymentType
    },
    data() {
        return {
            showDeleteCardConfirmation: false,
            cardToDelete: null
        }
    },
    props: {
        methodData: {
            type: Object,
            require: true
        }
    },
    computed: {
        cardData() {
            return Object.values(this.methodData.options)
        }
    },
    methods: {
        handleShowDeleteCardConfirmation(card) {
            this.cardToDelete = card
            this.showDeleteCardConfirmation = true
        },
        async handleDeleteCard() {
            try {
                const response = await apiClient.get('/account/deleteStoredCard/' + this.cardToDelete.id)
                if (!isResponseOk(response)) throw new Error()
                this.$store.dispatch('cards/setCards', response.data?.creditCards || [])
                this.$store.dispatch('notifications/showFlash', {
                    type: 'success',
                    message: 'Bank card deleted.'
                })
            } catch (error) {
                this.$store.dispatch('notifications/showFlash', {
                    type: 'error',
                    message: 'Error deleting this bank card. Please reload the page and try again.'
                })
            }
            this.cardToDelete = null
            this.showDeleteCardConfirmation = false
        },
        handleCancelDeleteCard() {
            this.cardToDelete = null
            this.showDeleteCardConfirmation = false
        }
    }
}
</script>