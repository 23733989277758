<template>
    <component :is="tag" :style="minHeight" class="relative">
        <slot></slot>
    </component>
</template>

<script>
import { mapState } from 'vuex'
import slideoutFormModule from '../store/modules/slideoutForm/slideoutForm'
const { state, getters, mutations, actions } = slideoutFormModule

export default {
    name: 'slideout-form-observer',
    provide() {
        return {
            slideoutFormModule: this.moduleName
        }
    },
    props: {
        tag: {
            type: String,
            default: 'div'
        },
        id: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapState({
            moduleName(state) {
                return state.modulePrefixes.slideoutForm + (this.id ? this.id : this._uid)
            }
        }),
        minHeight() {
            return {
                minHeight: this.$store.getters[`${this.moduleName}/elHeight`] + 'px'
            }
        }
    },
    created() {
        if (!this.$store.hasModule(this.moduleName)) {
            this.$store.registerModule(this.moduleName, {
                namespaced: true,
                state,
                getters,
                mutations,
                actions
            })
        }
    }
}
</script>