<template>
  <div class="relative">
    <button @click.prevent="toggleOptions" @keyup.delete="handleOptionRemoved" type="button"
      class="w-full flex justify-between items-center text-left font-bold text-lg px-4 py-2.5 hover:bg-grey-dark focus:outline-none focus:text-white focus:bg-grey-dark"
      :class="[
              btnClass,
              {
                'text-gray-950 bg-grey-pale hover:text-white': optionsVisible,
                'text-white bg-grey-blue hover:bg-grey-dark': !optionsVisible,
              }
            ]" :disabled="!isEnabled">
      <span v-show="!value">
        <slot></slot>
      </span>
      <span v-show="value" class="max-w-full overflow-hidden truncate whitespace-nowrap">{{ value.label || value
              }}</span>

      <!-- Cross and chevron icons -->
      <span class="flex items-center text-sm">
        <span v-if="value" @click.prevent.stop="handleOptionRemoved" class="ml-2"><i class="fas fa-times"></i></span>
        <span class="ml-2 transition-transform duration-200 rotate-0" :class="{ '-rotate-180': optionsVisible }"><i
            class="fas fa-chevron-down"></i></span>
      </span>
    </button>
    <v-select v-show="optionsVisible" :options="options" @input="handleOptionSelected" :value="value"
      :select-on-key-codes="[9, 13]" :map-keydown="mapKeyEvents" class="algolia-search-dropdown">
      <!-- searchbox -->
      <template slot="search" slot-scope="{ attributes, events }">
        <div class="p-1">
          <div class="flex w-full border bg-grey-pale border-grey-blue">
            <input class="min-w-0 p-1 bg-transparent grow focus:outline-none" ref="searchBox" v-bind="attributes"
              v-on="events" @blur="hideOptions">
            <span
              class="relative flex justify-center items-center text-grey-blue px-1.5 bg-transparent short-border short-border__left short-border__left--far">
              <i class="fas fa-search"></i>
            </span>
          </div>
        </div>
      </template>
      <!-- Inside each <li> -->
      <template slot="option" slot-scope="{ label, count }">
        <!-- TODO: When the counts are correct for the 'distinct' facets <span class="inline-block w-full px-2 py-1 font-semibold">{{ label }} ({{ count }})</span> -->
        <span class="block font-semibold">{{ label }} {{ name !== 'MAKE' && count !== null ? `(${count})` : ''
                  }}</span>
      </template>
      <!-- No options text -->
      <template slot="no-options">
        <span class="inline-block w-full px-2 py-1">Sorry, no matching options.</span>
      </template>
      <!-- Removing the chevron from the v-select UI -->
      <template slot="open-indicator"><span></span></template>
      <!-- Removing the selected option from the v-select UI -->
      <template slot="selected-option-container"><span></span></template>
    </v-select>
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  name: 'algolia-search-dropdown',
  components: {
    vSelect
  },
  inject: {
    findItFastModuleName: {
      default: null
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      default: "",
    },
    algoliaItems: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    refine: {
      type: Function,
      default: () => 1
    },
    isEnabled: {
      type: Boolean,
      default: true
    },
    openIfNextOption: {
      type: Boolean,
      default: false
    },
    btnClass: {
      type: String
    }
  },
  data() {
    return {
      optionsVisible: false
    }
  },
  created() {
    if (this.findItFastModuleName) {
      this.$store.dispatch(`${this.findItFastModuleName}/setRefineFunction`, {
        option: this.name,
        func: this.refine
      })

      if (this.openIfNextOption) {
        this.$watch(
          'lastOptionChanged',
          function ({ value }) {
            if (value) {
              if (this.optionToOpen === this.name) {
                this.showOptions()
              }
            }
          },
          { deep: true }
        )
      }
    }
  },
  computed: {
    lastOptionChanged() {
      if (this.findItFastModuleName && this.openIfNextOption) {
        return this.$store.state[this.findItFastModuleName].lastOptionChanged
      }
      return null
    },
    optionToOpen() {
      if (this.findItFastModuleName && this.openIfNextOption) {
        return this.$store.getters[`${this.findItFastModuleName}/nextOptionToOpen`]
      }
      return null
    }
  },
  methods: {
    toggleOptions() {
      this.optionsVisible ? this.hideOptions() : this.showOptions()
    },
    hideOptions() {
      this.optionsVisible = false
    },
    async showOptions() {
      this.optionsVisible = true
      await this.$nextTick()
      this.$refs.searchBox.focus()
    },
    handleOptionRemoved() {
      this.$emit('change', '')
    },
    handleOptionSelected(val) {
      this.$emit('change', val)
    },
    mapKeyEvents(keyMap) {
      const defaultTabFunction = keyMap[9]
      keyMap[9] = e => { if (!e.shiftKey) defaultTabFunction(e) }
      return keyMap
    }
  },
  watch: {
    algoliaItems: {
      immediate: true,
      handler(items) {
        if (!this.findItFastModuleName) return
        this.$store.dispatch(`${this.findItFastModuleName}/setOptionData`, {
          option: this.name,
          items: items,
        })
      }
    }
  }
};
</script>

<style lang="scss">
.algolia-search-dropdown {
  // Set the vue-select variables before importing the package styles
  //  Active State
  $vs-state-active-bg: theme('colors.orange.DEFAULT');
  $vs-state-active-color: theme('colors.white');

  //  Borders
  $vs-border-width: 2px;
  $vs-border-style: solid;
  $vs-border-radius: 0;
  $vs-border-color: theme('colors.gray.950');

  //  Dropdown
  $vs-dropdown-z-index: 100;
  $vs-dropdown-min-width: 0;
  $vs-dropdown-box-shadow: none;
  $vs-dropdown-bg: theme('colors.white');

  @import "vue-select/src/scss/vue-select.scss";

  &.v-select {
    @apply text-gray-950 absolute left-0 w-full;
    top: calc(100% + theme('spacing[1.5]'));
  }

  .vs {
    &__dropdown-toggle {
      @apply block bg-white p-0 m-0;
    }

    &__selected-options {
      @apply block p-0 m-0;
    }

    &__actions {
      @apply hidden;
    }

    &__dropdown-menu {
      @apply divide-y-2 divide-neutral-200 py-0;
    }

    &__dropdown-option {
      @apply px-2 py-1.5 whitespace-pre-line;
    }
  }
}
</style>
