import {
    MAKE,
    MODEL,
    YEAR,
    PRODUCT,
    SET_VALUE,
    ENABLE_OPTION,
    RESET_OPTIONS,
    SET_REFINE_FUNCTION,
    SET_URL,
    SET_OPTION_DATA
} from './_constants'

// This function will only make changes to the UI only and does not deal with refining the values for algolia
function resetOptions(state, option) {
    if (state.hasOwnProperty(option)) {
        state[option].value = ''
        if (!state[option].hasOwnProperty('linksTo')) {
            // This return breaks the recursion (as long as there is always one option without a 'linksTo' property)
            return
        } else {
            // Recursively disable the options that follow
            resetOptions(state, state[option].linksTo)
            state[state[option].linksTo].enabled = false
        }
    }
    return
}

const state = () => ({
    url: '',
    lastOptionChanged: {
        name: '',
        value: ''
    },
    [MAKE]: {
        items: [],
        value: '',
        linksTo: MODEL,
        refine: null
    },
    [MODEL]: {
        items: [],
        value: '',
        linksTo: YEAR,
        refine: null
    },
    [YEAR]: {
        items: [],
        value: '',
        linksTo: PRODUCT,
        refine: null
    },
    [PRODUCT]: {
        items: [],
        value: '',
        refine: null
    }
})

const getters = {
    filteredOptions: (state, getters) => {
        return {
            [MAKE]: getters.filteredMake,
            [MODEL]: getters.filteredModel,
            [YEAR]: getters.filteredYear,
            [PRODUCT]: getters.filteredProduct
        }
    },
    // Make
    makeItems: state => state[MAKE].items,
    filteredMake: (state, getters) => {
        if (state[MAKE].value) return state[MAKE].value
        else {
            const refinedItem = getters.makeItems.find(item => item.isRefined)
            return refinedItem ?? state[MAKE].value
        }
    },
    // Model
    modelItems: state => {
        const models = [...state[MODEL].items]
        const anyModelIndex = models.findIndex(model => model.label.toLowerCase() === 'any model')
        if (anyModelIndex !== -1) {
            const [ anyModel ] = models.splice(anyModelIndex, 1)
            return [anyModel, ...models]
        }
        return models
    },
    filteredModel: (state, getters) => {
        if (state[MODEL].value) return state[MODEL].value
        else {
            const refinedItem = getters.modelItems.find(item => item.isRefined)
            return refinedItem ?? state[MODEL].value
        }
    },
    isModelEnabled: (state, getters) => getters.filteredMake ? true : false,
    // Year
    yearItems: (state, getters) => {
        if (!getters.filteredModel || state[YEAR].items.length === 0) return state[YEAR].items
        const filteredItems = state[YEAR].items.filter(({ label }) => RegExp(getters.filteredModel.label + '\\s\\-\\s?', 'i').test(label))
        const renamedItems = filteredItems.map(item => ({
            ...item,
            label: item.label.replace(RegExp(getters.filteredModel.label + '.*?\\-\\s?', 'i'), '')
        }))
        const anyYearOption = renamedItems.splice(renamedItems.findIndex(item => item.label.match(/Any Year/)), 1)
        if (anyYearOption.length > 0) {
            renamedItems.unshift(anyYearOption[0])
            return renamedItems
        } else {
            return renamedItems
        }
    },
    filteredYear: (state, getters) => {
        if (state[YEAR].value) return state[YEAR].value
        else {
            const refinedItem = getters.yearItems.find(item => item.isRefined)
            return refinedItem ?? state[YEAR].value
        }
    },
    isYearEnabled: (state, getters) => getters.filteredModel ? true : false,
    // Product
    productItems: (state, getters) => {
        const catsCount = getters.filteredYear?.count || null
        return [{ label: 'Any Categories', value: null, count: catsCount }, ...state[PRODUCT].items]
    },
    filteredProduct: (state, getters) => {
        if (state[PRODUCT].value) return state[PRODUCT].value
        else {
            const refinedItem = getters.productItems.find(item => item.isRefined)
            return refinedItem ?? state[PRODUCT].value
        }
    },
    isProductEnabled: (state, getters) => getters.filteredYear ? true : false,
    nextOptionToOpen: state => (state.lastOptionChanged.value) ? state[state.lastOptionChanged.name]?.linksTo : '',
    optionChain: state => option => {
        // Get the options chain that this option links through to.
        const options = []
        let linkedOption = option
        do {
            options.unshift(linkedOption)
            linkedOption = state[linkedOption]?.linksTo
        } while (linkedOption)
        return options
    },
    valueToRefine: state => (option, value) => {
        let valueToRefine
        valueToRefine = value?.value ?? value
        if (!valueToRefine && state[option].value) {
            valueToRefine = state[option].value?.value ?? state[option].value
        }
        return valueToRefine
    }
}

const mutations = {
    [SET_OPTION_DATA](state, { option, items = [] }) {
        state[option].items = items
    },
    [SET_VALUE](state, { option, value }) {
        if (!state.hasOwnProperty(option) || value === state[option].value) return
        // recursive function
        resetOptions(state, option)
        state[option].value = value
        state.lastOptionChanged.name = option
        state.lastOptionChanged.value = value?.value ?? value
    },
    [ENABLE_OPTION](state, { option }) {
        if (!state.hasOwnProperty(option) || !state[option].hasOwnProperty('linksTo')) return
        state[state[option].linksTo].enabled = true
    },
    [RESET_OPTIONS](state, { option }) {
        // recursive function
        resetOptions(state, option)
        state.lastOptionChanged.name = option
        state.lastOptionChanged.value = ''
    },
    [SET_REFINE_FUNCTION](state, { option, func }) {
        if (!state.hasOwnProperty(option) || state[option].refine) return
        state[option].refine = func
    },
    [SET_URL](state, url) {
        state.url = url
    }
}

const actions = {
    setOptionData({ commit, state }, payload) {
        if (!state.hasOwnProperty(payload?.option)) return
        commit(SET_OPTION_DATA, payload)
    },
    /**
     * Whenever a value is changed, it will invoke this function first.
     * If there is no value, reset options recursively starting at the last option working backwards to the current option
     * If there is a value, we first reset options recursively starting at the last option working backwards to the current option, then we refine this single option
     */
    setOption({ commit, getters, state }, payload) {
        if (!state.hasOwnProperty(payload?.option)) return

        // Handle Algolia state only
        if (payload?.shouldRefine) {
            // Refine the options in the option chain if there are values to refine. This will reset values from algolias perspective.
            const optionChain = getters.optionChain(payload.option)
            optionChain.forEach(option => {
                const currentValue = getters.filteredOptions[option]
                if (currentValue && currentValue?.value) state[option].refine(getters.valueToRefine(option, currentValue)) // If there is an option set already, refine it to reset it

                if (option === PRODUCT && payload?.value?.value === null) return // If we are selecting the hard coded 'All Categories' option, don't refine
                else if (option === payload.option && payload?.value) state[option].refine(getters.valueToRefine(option, payload.value)) // Handle refining the option that was just selected
            })
        }

        // Handle UI state only
        if (payload?.value) {
            commit(SET_VALUE, payload)
            commit(ENABLE_OPTION, payload)
        } else {
            commit(RESET_OPTIONS, payload)
        }
    },
    resetAllOptions({ commit }) {
        commit(RESET_OPTIONS, { option: MAKE })
    },
    setRefineFunction({ commit }, payload) {
        commit(SET_REFINE_FUNCTION, payload)
    },
    setUrl({ commit }, url) {
        commit(SET_URL, url)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}