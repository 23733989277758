<template>
  <portal to="lightbox">
    <div ref="overlay" @click="handleClickOutside"
      class="fixed top-0 left-0 z-40 flex items-center justify-center w-full h-screen p-4 bg-grey-pale/50 sm:p-6">
      <slot></slot>
    </div>
  </portal>
</template>

<script>
export default {
  name: 'lightbox',
  methods: {
    handleClickOutside(e) {
      if (e.target === this.$refs.overlay) {
        this.$emit('click-outside')
      }
    }
  }
}
</script>
