var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('button',{staticClass:"w-full flex justify-between items-center text-left font-bold text-lg px-4 py-2.5 hover:bg-grey-dark focus:outline-none focus:text-white focus:bg-grey-dark",class:[
            _vm.btnClass,
            {
              'text-gray-950 bg-grey-pale hover:text-white': _vm.optionsVisible,
              'text-white bg-grey-blue hover:bg-grey-dark': !_vm.optionsVisible,
            }
          ],attrs:{"type":"button","disabled":!_vm.isEnabled},on:{"click":function($event){$event.preventDefault();return _vm.toggleOptions.apply(null, arguments)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"])){ return null; }return _vm.handleOptionRemoved.apply(null, arguments)}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.value),expression:"!value"}]},[_vm._t("default")],2),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.value),expression:"value"}],staticClass:"max-w-full overflow-hidden truncate whitespace-nowrap"},[_vm._v(_vm._s(_vm.value.label || _vm.value))]),_c('span',{staticClass:"flex items-center text-sm"},[(_vm.value)?_c('span',{staticClass:"ml-2",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleOptionRemoved.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-times"})]):_vm._e(),_c('span',{staticClass:"ml-2 transition-transform duration-200 rotate-0",class:{ '-rotate-180': _vm.optionsVisible }},[_c('i',{staticClass:"fas fa-chevron-down"})])])]),_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.optionsVisible),expression:"optionsVisible"}],staticClass:"algolia-search-dropdown",attrs:{"options":_vm.options,"value":_vm.value,"select-on-key-codes":[9, 13],"map-keydown":_vm.mapKeyEvents},on:{"input":_vm.handleOptionSelected},scopedSlots:_vm._u([{key:"search",fn:function(ref){
          var attributes = ref.attributes;
          var events = ref.events;
return [_c('div',{staticClass:"p-1"},[_c('div',{staticClass:"flex w-full border bg-grey-pale border-grey-blue"},[_c('input',_vm._g(_vm._b({ref:"searchBox",staticClass:"min-w-0 p-1 bg-transparent grow focus:outline-none",on:{"blur":_vm.hideOptions}},'input',attributes,false),events)),_c('span',{staticClass:"relative flex justify-center items-center text-grey-blue px-1.5 bg-transparent short-border short-border__left short-border__left--far"},[_c('i',{staticClass:"fas fa-search"})])])])]}},{key:"option",fn:function(ref){
          var label = ref.label;
          var count = ref.count;
return [_c('span',{staticClass:"block font-semibold"},[_vm._v(_vm._s(label)+" "+_vm._s(_vm.name !== 'MAKE' && count !== null ? ("(" + count + ")") : ''))])]}}])},[_c('template',{slot:"no-options"},[_c('span',{staticClass:"inline-block w-full px-2 py-1"},[_vm._v("Sorry, no matching options.")])]),_c('template',{slot:"open-indicator"},[_c('span')]),_c('template',{slot:"selected-option-container"},[_c('span')])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }