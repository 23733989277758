<template functional>
    <div class="fixed right-8 bottom-8">
        <portal-target name="notifications" multiple class="notifications"></portal-target>
    </div>
</template>

<script>
export default {
    name: 'notifications'
}
</script>

<style lang="scss" scoped>
.notifications {
    @apply flex flex-col items-end space-y-4;
}
</style>