<template functional>
    <simple-add-to-cart-form
        v-bind="data.attrs"
        class="group flex grow border-4 border-orange min-w-0 hover:border-orange-dark"
        :class="[data.staticClass, data.class]"
        input-class="bg-grey-pale text-xs text-grey-blue font-bold text-center w-9 min-w-0 hover:bg-white focus:bg-white"
        btn-class="grow flex justify-center items-center text-xs py-1 group-hover:bg-orange-dark"
    />
</template>

<script>
import SimpleAddToCartForm from './SimpleAddToCartForm'
export default {
    name: 'add-to-cart-input-button',
    components: {
        SimpleAddToCartForm
    }
}
</script>
