<template>
  <payment-type v-if="hasOptions" :heading="methodData.heading" :type="methodData.type" :has-error="hasError">
    <div class="flex flex-col md:flex-row text-sm text-grey-blue pr-16">
      <div class="grid gap-3.5 md:mr-8 credit-info">
        <!-- Basic info -->
        <ul class="col-span-full sm:col-span-1">
          <li class="font-bold">Credit Limit: {{ formattedLimit }}</li>
          <li>Credit Term: {{ term }} Days</li>
        </ul>

        <!-- Current usage -->
        <ul class="col-span-full sm:col-span-1 sm:justify-self-end md:justify-self-start">
          <li class="flex items-center">
            <span class="w-3.5 h-3.5 mr-1 flex-shrink-0" :class="{
              'bg-orange': !isOnStop,
              'bg-danger': isOnStop
            }"></span>
            <span :class="{ 'text-danger': isOnStop }">{{ formattedOutstanding }} Still To Be Paid</span>
          </li>
          <li class="flex items-center">
            <span class="w-3.5 h-3.5 mr-1 flex-shrink-0" :class="{
              'bg-orange-lighter': !isOnStop,
              'bg-pink': isOnStop
            }"></span>
            <span :class="{ 'text-danger': isOnStop }">{{ formattedOrderTotal }} For Current Order</span>
          </li>
        </ul>
      </div>

      <!-- Status bar -->
      <div class="flex flex-col grow pt-4 md:pt-0.5">
        <span class="bg-grey-pale relative h-3.5">
          <span class="flex absolute left-0 top-0 w-full h-full" :class="{ 'bg-danger': isExceedingLimit }">
            <span v-if="!isExceedingLimit" class="inline-block h-full" :class="{
              'bg-orange': !isOnStop,
              'bg-danger': isOnStop
            }" :style="percentageWidths.outstanding"></span>
            <span v-if="!isExceedingLimit" class="inline-block h-full" :class="{
              'bg-orange-lighter': !isOnStop,
              'bg-pink': isOnStop
            }" :style="percentageWidths.orderTotal"></span>
          </span>
        </span>
        <div class="flex justify-between mt-1">
          <span>£0</span>
          <span>{{ formattedLimit }}</span>
        </div>
      </div>
    </div>

    <!-- Warning messages -->
    <div v-if="hasError" class="flex items-baseline text-danger text-sm mt-3">
      <span
        class="flex-shrink-0 text-xxxs border-2 border-danger rounded-full w-5 h-5 flex justify-center items-center mr-2.5">
        <i class="fas fa-info"></i>
      </span>
      <p v-if="isOnStop">Your account is currently on stop.</p>
      <p v-else-if="isExceedingLimit">You will exceed your credit limit with this order.</p>
    </div>
  </payment-type>
</template>

<script>
import PaymentType from './PaymentType'
import paymentMethod from '../mixins/paymentMethod'

import currency from 'currency.js'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'credit-payment-type',
  mixins: [paymentMethod],
  components: {
    PaymentType
  },
  props: {
    methodData: {
      type: Object,
      require: true
    }
  },
  computed: {
    ...mapState('cart', {
      cartTotal: 'total'
    }),
    ...mapGetters('checkout', ['activeDeliveryMethodPrice']),
    orderTotal() {
      return currency(this.cartTotal).add(this.activeDeliveryMethodPrice).value
    },
    formattedOrderTotal() {
      return currency(this.orderTotal).format({ symbol: '£' })
    },
    term() {
      return this.methodData.options[0].accountCredit.days
    },
    limit() {
      return parseInt(this.methodData.options[0].accountCredit.amount)
    },
    formattedLimit() {
      return currency(this.methodData.options[0].accountCredit.amount).format({ symbol: '£' })
    },
    outstanding() {
      return parseInt(this.methodData.options[0].outstanding)
    },
    formattedOutstanding() {
      return currency(this.outstanding).format({ symbol: '£' })
    },
    percentageWidths() {
      return {
        outstanding: { width: `${this.percentageOfLimit(this.outstanding)}%` },
        orderTotal: { width: `${this.percentageOfLimit(this.orderTotal)}%` }
      }
    },
    isOnStop() {
      return Boolean(parseInt(this.methodData.options[0].accountCredit.onStop))
    },
    isExceedingLimit() {
      return this.limit === 0 || this.percentageOfLimit(this.outstanding) + this.percentageOfLimit(this.orderTotal) > 100
    },
    hasError() {
      return this.isOnStop || this.isExceedingLimit
    }
  },
  methods: {
    percentageOfLimit(amount) {
      return (amount * 100) / this.limit
    }
  }
}
</script>

<style lang="scss" scoped>
.credit-info {
  grid-template-columns: repeat(2, 1fr);

  @screen md {
    grid-template-columns: repeat(2, max-content);
  }
}
</style>
