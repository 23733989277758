<template functional>
    <svg xmlns="http://www.w3.org/2000/svg" width="18.333" height="18.333" viewBox="0 0 18.333 18.333" class="fill-none stroke-blue-dark">
        <g id="Icon" transform="translate(.457 1.666)">
            <circle id="Path" cx="8.333" cy="8.333" r="8.333" class="cls-1" transform="translate(.377 -.832)"/>
            <path id="Line" d="M0 3.333L0 0" class="cls-1" transform="translate(8.71 7.501)"/>
            <path id="Line-2" d="M0 0L0.008 0" class="cls-1" transform="translate(8.71 4.168)"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'icon-info'
}
</script>

<style lang="scss" scoped>
    .cls-1 {
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 1.667px;
    }
</style>