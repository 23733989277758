import {
    SET_ACTIVE,
    SET_INACTIVE,
    SET_HEIGHT,
} from './_constants'

const state = () => ({
    activeForms: [],
    elHeight: 0
})

const getters = {
    isActive: state => form => state.activeForms.includes(form),
    elHeight: state => state.elHeight
}

const mutations = {
    [SET_ACTIVE](state, form) {
        state.activeForms.push(form)
    },
    [SET_INACTIVE](state, form) {
        if (state.activeForms.includes(form)) {
            state.activeForms.splice(state.activeForms.indexOf(form), 1)
        }
    },
    [SET_HEIGHT](state, val) {
        state.elHeight = val
    }
}

const actions = {
    setActive({ commit }, form) {
        commit(SET_ACTIVE, form)
    },
    setInactive({ commit }, form) {
        commit(SET_INACTIVE, form)
    },
    setHeight({ commit }, val) {
        commit(SET_HEIGHT, val)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}