import {
    MAKE,
    MODEL,
    YEAR,
    PRODUCT
} from '../store/modules/findItFast/_constants'
import findItFastModule from '../store/modules/findItFast/findItFast'
const { state, getters, mutations, actions } = findItFastModule

export default {
    provide() {
        return {
            findItFastModuleName: this.moduleName
        }
    },
    props: {
        moduleName: {
            type: String,
            default: 'findItFast'
        }
    },
    created() {
        if (!this.$store.hasModule(this.moduleName)) {
            this.$store.registerModule(this.moduleName, {
                namespaced: true,
                state,
                getters,
                mutations,
                actions
            })
        }
        this.MAKE = MAKE
        this.MODEL = MODEL
        this.YEAR = YEAR
        this.PRODUCT = PRODUCT
    },
    computed: {
        makeItems() {
            return this.$store.getters[`${this.moduleName}/makeItems`]
        },
        modelItems() {
            return this.$store.getters[`${this.moduleName}/modelItems`]
        },
        yearItems() {
            return this.$store.getters[`${this.moduleName}/yearItems`]
        },
        productItems() {
            return this.$store.getters[`${this.moduleName}/productItems`]
        },
        isModelEnabled() {
            return this.$store.getters[`${this.moduleName}/isModelEnabled`]
        },
        isYearEnabled() {
            return this.$store.getters[`${this.moduleName}/isYearEnabled`]
        },
        isProductEnabled() {
            return this.$store.getters[`${this.moduleName}/isProductEnabled`]
        },
        filteredMake: {
            get() {
                return this.$store.getters[`${this.moduleName}/filteredMake`]
            },
            set(val) {
                this.$store.dispatch(`${this.moduleName}/setOption`, {
                    option: this.MAKE,
                    value: val,
                    shouldRefine: true
                })
            }
        },
        filteredModel: {
            get() {
                return this.$store.getters[`${this.moduleName}/filteredModel`]
            },
            set(val) {
                this.$store.dispatch(`${this.moduleName}/setOption`, {
                    option: this.MODEL,
                    value: val,
                    shouldRefine: true
                })
            }
        },
        filteredYear: {
            get() {
                return this.$store.getters[`${this.moduleName}/filteredYear`]
            },
            set(val) {
                this.$store.dispatch(`${this.moduleName}/setOption`, {
                    option: this.YEAR,
                    value: val,
                    shouldRefine: true
                })
            }
        },
        filteredProduct: {
            get() {
                return this.$store.getters[`${this.moduleName}/filteredProduct`]
            },
            set(val) {
                this.$store.dispatch(`${this.moduleName}/setOption`, {
                    option: this.PRODUCT,
                    value: val,
                    shouldRefine: true
                })
            }
        }
    }
}