<template>
    <button type="button" @click.prevent="handleClick">
        <slot>Set Default</slot>
    </button>    
</template>

<script>
export default {
    name: 'set-default-address',
    inject: {
        addressesModule: { default: null }
    },
    computed: {
        billingId() {
            return this.$store.getters[`${this.addressesModule}/billing`]
        },
        shippingId() {
            return this.$store.getters[`${this.addressesModule}/shipping`]
        }
    },
    methods: {
        handleClick() {
            if (this.addressesModule) {
                this.$store.dispatch(`${this.addressesModule}/setDefaultAddress`, {
                    billingAddressId: this.billingId,
                    shippingAddressId: this.shippingId
                })
            }
        }
    }
}
</script>