<template functional>
    <ul class="space-y-1.5" :class="[data.staticClass, data.class]">
        <li 
            v-for="item in props.items" 
            :key="item.value" 
            :class="props.unrefinedItems && props.unrefinedItems.includes(item) ? 'hidden' : 'block'"
        >
            <a
                class="facet"
                :class="item.isRefined && 'facet--selected'"
                :href="props.createUrl(item.value)"
                @click.prevent="props.refine(item.value)"
            >
                <span v-if="item.isRefined"><i class="fas fa-times"></i></span>
                <span :class="{ 'mx-auto px-1': item.isRefined }">{{ item.label }} ({{ item.count.toLocaleString() }})</span>
            </a>

            <hierarchical-menu-list
                v-if="item.data"
                :items="item.data"
                :refine="props.refine"
                :create-url="props.createUrl"
                class="mt-1.5 ml-2 mr-2 lg:mr-0"
            />
        </li>
    </ul>
</template>

<script>
export default {
    name: 'hierarchical-menu-list'
}
</script>