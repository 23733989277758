import {
    SET_IS_AUTH,
    SET_IS_GUEST,
    SET_LOGIN_POPUP_TEXT,
    SET_IS_LOGIN_POPUP_ACTIVE
} from './_constants'

const state = () => ({
    isAuth: false,
    isGuest: true,
    isLoginPopupActive: false,
    loginPopupText: ''
})

const getters = {
    isAuth: state => state.isAuth,
    isGuest: state => state.isGuest,
    isLoginPopupActive: state => state.isLoginPopupActive,
    loginPopupText: state => state.loginPopupText
}

const mutations = {
    [SET_IS_AUTH](state, val) {
        state.isAuth = val
    },
    [SET_IS_GUEST](state, val) {
        state.isGuest = val
    },
    [SET_IS_LOGIN_POPUP_ACTIVE](state, val) {
        state.isLoginPopupActive = val
    },
    [SET_LOGIN_POPUP_TEXT](state, text) {
        state.loginPopupText = text
    }
}

const actions = {
    setIsAuth({ commit }, val) {
        commit(SET_IS_AUTH, val)
    },
    setIsGuest({ commit }, val) {
        commit(SET_IS_GUEST, val)
    },
    closeLoginPopup({ commit }) {
        commit(SET_IS_LOGIN_POPUP_ACTIVE, false)
    },
    showLoginPopup({ commit, dispatch }, text = '') {
        commit(SET_IS_LOGIN_POPUP_ACTIVE, true)
        commit(SET_LOGIN_POPUP_TEXT, text)
        dispatch('dynamicImport/load', 'loginRequiredPopup', { root: true })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}