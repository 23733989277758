import { IMAGE_PLACEHOLDER } from '../store/constants'

export default {
    name: 'product-placeholder',
    object: {
        bind: function(el, binding) {
            const styles = binding.value
            el.onerror = (event) => {
                el.onerror = null
                el.src = IMAGE_PLACEHOLDER
                if (typeof styles === 'object' && styles !== null) {
                    for (const style in styles) {
                        el.style[style] = styles[style]
                    }
                }
            }
        }
    }
}