<template functional>
    <svg xmlns="http://www.w3.org/2000/svg" width="19.128" height="16.753" viewBox="0 0 19.128 16.753" class="fill-none stroke-olive-dark">
        <g id="Icon" transform="translate(.853 .918)">
            <path id="Path" d="M8.575 3.217L1.517 15a1.666 1.666 0 0 0 1.425 2.5h14.116a1.666 1.666 0 0 0 1.425-2.5L11.425 3.217a1.666 1.666 0 0 0-2.85 0z" class="cls-1" transform="translate(-1.29 -2.499)"/>
            <path id="Line" d="M0 0L0 3.333" class="cls-1" transform="translate(8.71 5.001)"/>
            <path id="Line-2" d="M0 0L0.008 0" class="cls-1" transform="translate(8.71 11.668)"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'icon-warning'
}
</script>

<style lang="scss" scoped>
    .cls-1 {
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 1.667px;
    }
</style>