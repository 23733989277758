<template>
    <form ref="form" @submit.prevent="handleSubmit">
        <slot :pending="isPending"></slot>
    </form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import eventBus from '../pubsub/eventBus'
import addedToCartPopupModule from '../store/modules/addedToCartPopup/addedToCartPopup'

import { NOTIFICATION, LIGHTBOX } from '../store/modules/feedback/_constants'

export default {
    name: 'ajax-add-to-cart',
    props: {
        updateItemsYouMayNeed: {
            type: Boolean,
            default: true
        },
        displayFeedback: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isPending: false,
            result: {}
        }
    },
    computed: {
        ...mapState('notifications', {
            productNotificationPrefix: state => state.productPrefix
        }),
        ...mapGetters('feedback', [
            'addedToCartFeedbackMethod'
        ])
    },
    methods: {
        ...mapActions('cart', [
            'createOrUpdateEntry',
        ]),
        ...mapActions('notifications', [
            'showNotification'
        ]),
        ...mapActions('addedToCartPopup', [
            'showPopup'
        ]),
        async handleSubmit() {
            this.isPending = true
            try {
                const formData = new FormData(this.$refs.form)
                this.result = await this.createOrUpdateEntry({
                    formData: Object.fromEntries(formData.entries()),
                    shouldUpdateItemsYouMayNeed: this.updateItemsYouMayNeed
                })
                if (!this.result?.quantityTotalSum) {
                    this.$store.dispatch('notifications/showFlash', {
                        type: 'warning',
                        message: 'This product has been removed from the cart.'
                    })
                    this.$emit('removed-from-cart')
                } else {
                    this.handleFeedback()
                    this.$emit('added-to-cart', this.result)
                    // TODO: Could look at potentially removing the $emit above, and just using the eventBus?
                    eventBus.$emit('added-to-cart')
                }
            } catch (error) {
                if (error.message === 'YII_LOGIN_REQUIRED') {
                    this.$store.dispatch('auth/showLoginPopup', 'You must be logged in to add a product to your basket.')
                } else {
                    const errorMessage = error.message || 'Unable to update cart. Please reload the page and try again.'
                    this.$store.dispatch('notifications/showFlash', {
                        type: 'error',
                        message: errorMessage
                    })
                }
            }
            this.isPending = false
        },
        handleFeedback() {
            if (!this.displayFeedback) return

            if (!this.$store.hasModule('addedToCartPopup')) this.$store.registerModule('addedToCartPopup', addedToCartPopupModule)

            switch (this.addedToCartFeedbackMethod) {
                case NOTIFICATION:
                    this.showNotification(this.productNotificationPrefix + this.result.productID)
                    break
                case LIGHTBOX:
                    this.showPopup(this.result.productID)
                    break
                default:
                    return
            }
        }
    }
}
</script>