import {
    SET_ACTIVE_OPTION,
    SET_PROGRESS_BAR_OPTION,
    SET_PROGRESS_BAR_WIDTH_OFFSET
} from './_constants'

const state = () => ({
    options: [],
    activeOption: null,
    widthOffset: {
        start: 0,
        end: 0
    }
})

const getters = {
    activeOption: state => state.activeOption,
    activeOptionIndex: state => state.options.findIndex(option => option === state.activeOption),
    options: state => state.options,
    percentageComplete: (state, getters) => getters.activeOption ? getters.activeOptionIndex * 100 / (getters.options.length - 1) : 0,
    isFirstOption: state => name => state.options.indexOf(name) === 0,
    isLastOption: state => name => state.options.indexOf(name) === state.options.length - 1,
    totalWidthOffset: state => state.widthOffset.start + state.widthOffset.end
}

const mutations = {
    [SET_ACTIVE_OPTION] (state, { value }) {
        state.activeOption = value
    },
    [SET_PROGRESS_BAR_OPTION] (state, { value }) {
        state.options.push(value)
    },
    [SET_PROGRESS_BAR_WIDTH_OFFSET] (state, payload) {
        if (typeof payload === 'object') {
            for (const key in payload) {
                if (key in state.widthOffset) {
                    state.widthOffset = Object.assign({}, state.widthOffset, payload)
                }
            }
        }
    }
}

const actions = {
    setActiveOption({ commit }, payload) {
        commit(SET_ACTIVE_OPTION, payload)
    },
    setProgressBarOption({ commit }, payload) {
        commit(SET_PROGRESS_BAR_OPTION, payload)
    },
    setWidthOffset({ commit }, payload) {
        commit(SET_PROGRESS_BAR_WIDTH_OFFSET, payload)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}