import {
    TOGGLE_SHOW_PRICES
} from './_constants'

const lsKey = 'shouldShowPrices'

const state = () => ({
    showPrices: JSON.parse(localStorage.getItem(lsKey)) ?? true
})

const mutations = {
    [TOGGLE_SHOW_PRICES](state) {
        const value = !state.showPrices
        state.showPrices = value
        localStorage.setItem(lsKey, JSON.stringify(value))
    }
}

const actions = {
    toggleShowPrices({ commit }) {
        commit(TOGGLE_SHOW_PRICES)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}