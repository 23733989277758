<template>
    <component :is="tag" v-show="showPrices">
        <slot></slot>
    </component>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('priceHider')

export default {
    name: 'price-hider',
    props: {
        tag: {
            type: String,
            default: 'div'
        }
    },
    computed: {
        ...mapState(['showPrices'])
    }
}
</script>