<template>
    <component :is="tag" v-show="isActive">
        <slot></slot>
    </component>
</template>

<script>
export default {
    name: 'tab-content',
    inject: {
        tabsModule: { default: null }
    },
    props: {
        name: {
            type: String,
            required: true
        },
        tag: {
            type: String,
            default: 'div'
        }
    },
    computed: {
        activeTab() {
            return this.$store.getters[`${this.tabsModule}/activeTab`]
        },
        isActive() {
            return this.activeTab === this.name
        }
    }
}
</script>