import apiClient from '../../../../xhr/apiClient'
import isResponseOk from '../../../../helpers/isResponseOk'
import {
    SET_CARDS
} from './_constants'

const state = () => ({
    cards: [] // Array<{...}>
})

const getters = {
    cards: state => state.cards
}

const mutations = {
    [SET_CARDS](state, cards) {
        state.cards = cards
    }
}

const actions = {
    setCards({ commit }, cards) {
        commit(SET_CARDS, cards)
    },
    async fetchCards({ dispatch }) {
        try {
            const response = await apiClient.get('account/bankCardManagement')
            if (!isResponseOk(response)) throw new Error()
            dispatch('setCards', response?.data?.creditCards ?? [])
        } catch (error) {
            error.message = error.message ? error.message : 'Error fetching all cards. Please reload the page. If the error persists, please contact a member of our team.'
            throw error
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}