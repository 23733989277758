import {
    SET_OPEN_ACCORDION
} from './_constants'

const state = () => ({
    idOfOpenAccordion: null
})

const getters = {
    idOfOpenAccordion: state => state.idOfOpenAccordion
}

const mutations = {
    [SET_OPEN_ACCORDION] (state, { id }) {
        state.idOfOpenAccordion = id
    }
}

const actions = {
    setOpenAccordion({ commit }, payload) {
        commit(SET_OPEN_ACCORDION, payload)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}