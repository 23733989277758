import {
    SET_LINE_ITEMS,
    SET_SUB_TOTAL,
    SET_TOTAL_ITEMS,
    SET_TOTAL_PRICE,
    SET_VAT_PERCENTS,
    SET_ITEMS_YOU_MAY_NEED,
    UPDATE_QTY_ADDED,
    REMOVE_QTY_ADDED,
    TOGGLE_USING_REWARD_POINTS,
    SET_USING_REWARD_POINTS
} from './_constants'
import ajaxFormClient from '../../../../xhr/ajaxFormClient'
import isResponseOk from '../../../../helpers/isResponseOk'
import currency from 'currency.js'

const state = () => ({
    totalItems: 0,
    subTotal: 0,
    vat: {},
    total: 0,
    lineItems: [], // Array<{ id: number|string; image: { thumb: string }; price: string; qty: number; sku: string; title: string; }>
    itemsYouMayNeed: [],
    itemQtyAdded: {},
    isUsingRewardPoints: false
})

const getters = {
    findProductById: state => productId => state.lineItems.find(item => item.id == productId),
    qtyInCart: (state, getters) => productId => {
        const item = getters.findProductById(productId)
        return item?.qty ? parseInt(item.qty) : 0
    },
    formattedDiscountPrice: (state, getters) => productId => {
        const item = getters.findProductById(productId)
        return item?.discountPrice ? item.discountPrice : null
    },
    lineItems: state => state.lineItems,
    itemsYouMayNeed: state => state.itemsYouMayNeed,
    qtyAdded: state => productId =>  state.itemQtyAdded[productId],
    isUsingRewardPoints: state => state.isUsingRewardPoints
}

const mutations = {
    [SET_TOTAL_ITEMS](state, amount) {
        state.totalItems = amount
    },
    [SET_SUB_TOTAL](state, amount) {
        state.subTotal = amount
    },
    [SET_VAT_PERCENTS](state, payload) {
        state.vat = payload
    },
    [SET_TOTAL_PRICE](state, amount) {
        state.total = amount
    },
    [SET_LINE_ITEMS](state, items) {
        const lineItems = items.map(item => {
            return {
                ...item.product,
                price: item.price,
                discountPrice: item.discountPrice,
                qty: item.qty,
                linePrice: currency(item.discountPrice).multiply(item.qty).format({ symbol: '£' })
            }
        })
        state.lineItems = lineItems
    },
    [SET_ITEMS_YOU_MAY_NEED](state, payload) {
        if (payload && Array.isArray(payload)) {
            state.itemsYouMayNeed = payload
        }
    },
    [UPDATE_QTY_ADDED](state, payload) {
        if (!payload || typeof payload !== 'object') return
        const { productId, quantityAdded } = payload
        // Add (or create) the quantity just added to the `itemQtyAdded` object using the productId As the key
        state.itemQtyAdded = Object.assign(
            {}, 
            state.itemQtyAdded, 
            { 
                [productId]: state.itemQtyAdded?.[productId] ? state.itemQtyAdded[productId] + quantityAdded : quantityAdded
            }
        )
    },
    [REMOVE_QTY_ADDED](state, productId) {
        delete state.itemQtyAdded[productId]
    },
    [TOGGLE_USING_REWARD_POINTS](state) {
        state.isUsingRewardPoints = !state.isUsingRewardPoints
    },
    [SET_USING_REWARD_POINTS](state, val) {
        state.isUsingRewardPoints = val
    }
}

const setTotalItems = 'setTotalItems'
const setSubTotal = 'setSubTotal'
const setVATPercents = 'setVATPercents'
const setTotalPrice = 'setTotalPrice'
const setLineItems = 'setLineItems'
const setItemsYouMayNeed = 'setItemsYouMayNeed'
const updateQtyAdded = 'updateQtyAdded'
const actions = {
    [setTotalItems]({ commit }, amount) {
        commit(SET_TOTAL_ITEMS, amount)
    },
    [setSubTotal]({ commit }, amount) {
        commit(SET_SUB_TOTAL, amount)
    },
    [setVATPercents]({ commit }, payload) {
        commit(SET_VAT_PERCENTS, payload)
    },
    [setTotalPrice]({ commit }, amount) {
        commit(SET_TOTAL_PRICE, amount)
    },
    [setLineItems]({ commit }, items) {
        commit(SET_LINE_ITEMS, items)
    },
    [setItemsYouMayNeed]({ commit }, payload) {
        commit(SET_ITEMS_YOU_MAY_NEED, payload)
    },
    [updateQtyAdded]({ commit }, payload) {
        commit(UPDATE_QTY_ADDED, payload)
    },
    removeQtyAdded({ commit }, productId) {
        commit(REMOVE_QTY_ADDED, productId)
    },
    toggleUsingRewardPoints({ commit }) {
        commit(TOGGLE_USING_REWARD_POINTS)
    },
    setUsingRewardPoints({ commit }, val) {
        commit(SET_USING_REWARD_POINTS, val)
    },
    async createOrUpdateEntry({ dispatch }, { formData, shouldUpdateItemsYouMayNeed }) {
        try {
            const response = await ajaxFormClient.post('shoppingCart/create', formData)
            if (response?.data === 'YII_LOGIN_REQUIRED') throw new Error('YII_LOGIN_REQUIRED')
            if (!isResponseOk(response) || response.data?.status !== 'success') throw new Error()
            const { totalItems, SubTotal, totals, lineItems, productID, quantityAdded } = response.data
            dispatch(setTotalItems, totalItems)
            dispatch(setSubTotal, SubTotal)
            dispatch(setVATPercents, totals.vatPercents)
            dispatch(setTotalPrice, totals.totalAmount)
            dispatch(setLineItems, lineItems)
            dispatch(updateQtyAdded, { productId: productID, quantityAdded: parseInt(quantityAdded) })
            if (shouldUpdateItemsYouMayNeed) dispatch(setItemsYouMayNeed, response.data?.itemsYouMayNeed ?? [])
            return response.data
        } catch (error) {
            throw error ?? new Error()
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}