<template>
  <div :class="{ 'pointer-events-none': !isActive }">
    <div
      class="absolute top-0 right-0 z-10 flex items-start justify-end w-full min-h-full overflow-hidden transition-opacity duration-300 ease-in-out opacity-0 bg-grey-pale/50"
      :class="[
        formClass,
        { 'opacity-100': isActive }
      ]" ref="formContainer">
      <div class="max-w-full transition-all duration-300 ease-in-out translate-x-full"
        :class="{ '!translate-x-0': isActive }" v-click-outside="vcoConfig" ref="form">
        <div class="p-6 bg-white border border-grey-dark"
          :class="{ 'max-h-screen overflow-scroll hide-scrollbar': maxHeightScreen }">
          <slot :loadContent="loadContent"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '../pubsub/eventBus'
import { throttle } from 'throttle-debounce'

export default {
  name: 'slideout-form',
  inject: {
    slideoutFormModule: { default: null }
  },
  props: {
    formClass: {
      type: String,
      default: ''
    },
    for: {
      type: String,
      required: true
    },
    maxHeightScreen: {
      type: Boolean,
      default: false
    },
    follow: {
      type: Boolean,
      default: false
    },
    excludedCloseFormEvents: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loadContent: false,
      vcoConfig: {
        handler: this.handleClickOutside,
        middleware: this.clickOutsideMiddleware
      },
    }
  },
  computed: {
    isActive() {
      return this.$store.getters[`${this.slideoutFormModule}/isActive`](this.for)
    },
    resizeObserver() {
      return new ResizeObserver(() => {
        this.setHeight()
        this.setPosition()
      })
    }
  },
  methods: {
    clickOutsideMiddleware() {
      return this.isActive
    },
    handleClickOutside() {
      this.closeForm()
    },
    closeForm() {
      this.$store.dispatch(`${this.slideoutFormModule}/setInactive`, this.for)
    },
    setHeight() {
      this.$store.dispatch(`${this.slideoutFormModule}/setHeight`, this.$refs.form.offsetHeight)
    },
    setPosition() {
      if (!this.follow) return

      // Do not run if we can still see the top of the container
      const distFromTop = this.$refs.formContainer.getBoundingClientRect().top
      if (distFromTop > 0) return

      // Get distance and height values
      const spacing = Math.floor(Math.abs(distFromTop))
      const form = this.$refs.form
      const formHeight = form.offsetHeight
      const parentHeight = this.$refs.formContainer.offsetHeight

      // Do not run if the container is smaller/equal to the form in size
      if (parentHeight <= formHeight) return

      // Set the position in the users view and ensure we don't go beyond the bottom of the container
      if ((spacing + formHeight) > parentHeight) {
        form.style.marginTop = Math.floor(parentHeight - formHeight) + 'px'
      } else {
        form.style.marginTop = spacing + 'px'
      }
    }
  },
  mounted() {
    const closeFormEvents = ['ajax-form-success', 'address-form-submit-success', 'add-card-success', 'add-card-error']
    closeFormEvents.forEach(event => {
      if (!this.excludedCloseFormEvents.includes(event)) eventBus.$on(event, this.closeForm)
    })

    // Set position of form on scroll
    if (this.follow) {
      const throttleSetPosition = throttle(50, this.setPosition);
      window.addEventListener('scroll', throttleSetPosition)
    }
  },
  watch: {
    isActive(val) {
      if (val) {
        this.loadContent = true
        this.setHeight()
        this.setPosition()
        this.resizeObserver.observe(this.$refs.form)
      } else {
        this.resizeObserver.unobserve(this.$refs.form)
      }
    }
  }
}
</script>
