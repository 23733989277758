<template>
    <button @click.prevent.stop="handleClick">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'slideout-form-toggle',
    inject: {
        slideoutFormModule: { default: null }
    },
    props: {
        for: {
            type: String,
            required: true
        }
    },
    computed: {
        isActive() {
            return this.$store.getters[`${this.slideoutFormModule}/isActive`](this.for)
        }
    },
    methods: {
        handleClick() {
            this.$store.dispatch(`${this.slideoutFormModule}/${this.isActive ? 'setInactive' : 'setActive'}`, this.for)
            this.$emit('clicked')
        }
    }
}
</script>