<template>
    <div class="inline-flex justify-center items-center">
        <button type="button" @click.prevent="handleClick" class="w-full h-full" :class="[ btnClass, { [loadingClass]: isPending } ]">
            <slot name="button-text">
                <i class="fas fa-trash-alt"></i>
            </slot>
        </button>

        <confirm-lightbox 
            v-if="showConfirm" 
            @confirm="deleteItem"
            @cancel="closeLightbox"
            container-class="max-w-96"
        >
            <template slot="heading-text">Are you sure you want to delete this item?</template>

            <slot></slot>

        </confirm-lightbox>
    </div>
</template>

<script>
import apiClient from '../../xhr/apiClient'
import isResponseOk from '../../helpers/isResponseOk'
import { mapActions } from 'vuex'
import eventBus from '../pubsub/eventBus'

export default {
    name: 'ajax-delete-item',
    components: {
        ConfirmLightbox: () => import(/* webpackChunkName: "confirm-lightbox" */'./ConfirmLightbox.vue')
    },
    props: {
        itemId: {
            required: true
        },
        loadingClass: {
            type: String,
            default: 'is-loading'
        },
        btnClass: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            showConfirm: false,
            isPending: false
        }
    },
    methods: {
        ...mapActions('cart', [
            'setTotalItems',
            'setSubTotal',
            'setVATPercents',
            'setTotalPrice',
            'setLineItems'
        ]),
        handleClick() {
            this.showConfirm = true
        },
        closeLightbox() {
            this.showConfirm = false
        },
        async deleteItem() {
            this.closeLightbox()
            this.isPending = true
            try {
                const response = await apiClient.delete(`/shoppingCart/delete?id=${this.itemId}&json=true`)
                if (!isResponseOk(response)) throw new Error()
                const { totalItems, SubTotal, totals, lineItems} = response.data
                // TODO: This is very similar to the AjaxAddToCart submit handling. Refactor to reduce duplication.
                this.setTotalItems(totalItems)
                this.setSubTotal(SubTotal)
                this.setVATPercents(totals.vatPercents)
                this.setTotalPrice(totals.totalAmount)
                this.setLineItems(lineItems)
                eventBus.$emit('deleted-item-from-cart')
            } catch (error) {
                this.$store.dispatch('notifications/showFlash', {
                    type: 'error',
                    message: 'There was an error deleting this item.'
                })
            }
            this.isPending = false
        }
    }
}
</script>