import { TOGGLE_MEGA_MENU } from './_constants'
import { TOGGLE_OVERLAY } from '../../constants'

const state = () => ({
    nameOfMenuOpen: ''
})

const getters = {
    nameOfMenuOpen: state => state.nameOfMenuOpen
}

const mutations = {
    [TOGGLE_MEGA_MENU] (state, { name }) {
        state.nameOfMenuOpen = name
    }
}

const actions = {
    toggleMegaMenu({ commit }, { name, isOverlayOpen}) {
        commit(TOGGLE_OVERLAY, { isOpen: isOverlayOpen }, { root: true })
        commit(TOGGLE_MEGA_MENU, { name })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}