<template>
    <span class="is-loading" :class="css"></span>
</template>

<script>
export default {
    name: 'loading-indicator',
    props: {
        orange: {
            type: Boolean,
            default: true
        },
        size: {
            type: String,
            default: '',
            validator: str => /(small|large)/.test(str) || str === ''
        }
    },
    computed: {
        css() {
            let css = ''

            if (this.orange) css += 'is-loading--orange '

            // Executing switch instead of string interpolation here so purgeCss doesn't miss this
            switch(this.size) {
                case 'small':
                    css += 'is-loading--small '
                    break
                case 'large':
                    css += 'is-loading--large '
                    break
                default:
                    css = css
            }

            return css
        }
    }
}
</script>