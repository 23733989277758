<template>
    <component :is="tag">
        <slot
            name="default"
            :totalItems="totalItems"
            :subTotal="formattedSubTotal"
            :total="formattedTotal"
            :vat="formattedVat"
            :productQty="qtyInCart"
            :lineItems="lineItems"
            :isUsingRewardPoints="isUsingRewardPoints"
            :isOutOfStock="isOutOfStock"
            :isSpecialOrder="isSpecialOrder"
        ></slot>
    </component>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import currency from 'currency.js'

export default {
    name: 'cart',
    props: {
        tag: {
            type: String,
            default: 'span'
        },
        currencySymbol: {
            type: String,
            default: '£'
        }
    },
    computed: {
        ...mapGetters('cart', [ 'qtyInCart', 'lineItems', 'isUsingRewardPoints' ]),
        ...mapState('cart', { 
            totalItems: 'totalItems',
            formattedSubTotal({ subTotal }) {
                return currency(subTotal).format({ symbol: this.currencySymbol })
            },
            formattedTotal({ total }) {
                return currency(total).format({ symbol: this.currencySymbol })
            },
            formattedVat({ vat }) {
                if (typeof vat === 'object' && Object.keys(vat).length > 0) {
                    // return example: [{ percentage: '20%', value: '£123.00' }, { percentage: '30%', value: '£456.00' }]
                    const vatArr = []
                    for (const key in vat) {
                        vatArr.push({
                            percentage: `${key}%`,
                            value: currency(vat[key]).format({ symbol: this.currencySymbol })
                        })
                    }
                    return vatArr
                }
                return null
            }
        }),
        ...mapGetters('stockLevel', [ 'isOutOfStock', 'isSpecialOrder' ])
    }
}
</script>