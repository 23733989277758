<template>
  <accordion class="bg-gray-50 border-2 border-grey-blue px-3.5" active-class="!bg-white shadow-box-small" :tag="tag"
    :duration="duration" :default-active="defaultActive">
    <template slot="title" slot-scope="{ isOpen, transitionDuration }">
      <span class="flex items-center justify-between py-3 font-bold uppercase text-grey-blue" :class="titleClass">
        <slot name="title">Set Title Slot</slot>
        <i class="text-sm rotate-0 fas fa-chevron-down text-grey-blue"
          :class="[transitionDuration, { '-rotate-180': isOpen }]"></i>
      </span>
    </template>

    <template slot="default" slot-scope="{ recalculateHeight }">
      <slot :recalculateHeight="recalculateHeight"></slot>
    </template>
  </accordion>
</template>

<script>
import Accordion from './Accordion'

export default {
  name: 'accordion-menu',
  components: {
    Accordion
  },
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    duration: {
      type: [String, Number],
      default: 300
    },
    defaultActive: {
      type: Boolean,
      default: false
    },
    titleClass: {
      type: String,
      default: ''
    }
  }
}
</script>
