import { SPECIAL_ORDER, LOW, MEDIUM, HIGH, UNKNOWN, OUT_OF_STOCK } from './_constants'

const state = () => ({})

const getters = {
  levelType: state => stockLevelObj => {
    if (!stockLevelObj) return ''
    const [level] = Object.keys(stockLevelObj.position) ?? [null]
    return level ?? ''
  },
  isSpecialOrder: (state, getters) => stockLevelObj => {
    if (!stockLevelObj) return false
    const level = getters.levelType(stockLevelObj)
    return level === SPECIAL_ORDER
  },
  isOutOfStock: (state, getters) => stockLevelObj => {
    if (!stockLevelObj) return false
    const level = getters.levelType(stockLevelObj)
    return level === OUT_OF_STOCK
  },
  stockLevel: (state, getters) => stockLevelObj => {
    if (!stockLevelObj) return {}
    const level = getters.levelType(stockLevelObj)
    const text = stockLevelObj.position?.[level] ?? ''

    switch (level) {
      case LOW:
        return {
          colour: 'bg-orange-dark',
          barWidth: 'w-3/12',
          amount: 'Low Stock',
          text
        }
      case MEDIUM:
        return {
          colour: 'bg-yellow-dark',
          barWidth: 'w-1/2',
          amount: 'Med Stock',
          text
        }
      case HIGH:
        return {
          colour: 'bg-green-light',
          barWidth: 'w-full',
          amount: 'High Stock',
          text
        }
      case SPECIAL_ORDER:
        return {
          colour: 'bg-gray-950',
          barWidth: 'w-0.5',
          amount: 'Special Order',
          text
        }
      case OUT_OF_STOCK:
        return {
          colour: 'bg-crimson',
          barWidth: 'w-0.5',
          amount: 'No Stock',
          text
        }
      case UNKNOWN:
        return {
          colour: 'bg-crimson',
          barWidth: 'w-0.5',
          amount: 'Unknown',
          text
        }
      default:
        return {
          colour: 'bg-crimson',
          barWidth: 'w-0.5',
          amount: 'Unknown',
          text: 'Unknown'
        }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters
}
