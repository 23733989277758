<template>
    <component :is="tag">
        <slot :cards="cards"></slot>
    </component>
</template>

<script>
import cardsModule from '../store/modules/cards/cards'

import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('cards')

export default {
    name: 'cards',
    props: {
        tag: {
            type: String,
            default: 'div'
        },
        initCards: {
            type: Array,
            required: true
        }
    },
    computed: {
        ...mapGetters([
            'cards'
        ])
    },
    methods: {
        ...mapActions([
            'setCards'
        ])
    },
    created() {
        if (!this.$store.hasModule('cards')) this.$store.registerModule('cards', cardsModule)
    },
    mounted() {
        this.setCards(this.initCards)
    }
}
</script>