import {
    SHOW_NOTIFICATION,
    REMOVE_NOTIFICATION,
    ADD_NOTIFICATION_TIMEOUT_ID,
    REMOVE_NOTIFICATION_TIMEOUT,
    ADD_GLOBAL,
    SET_NOTIFICATION_OPTIONS
} from './_constants'

const state = () => ({
    active: [],
    timeoutIds: [],
    globals: [],
    options: [], // Array<{ id, useTimeout, timout }>
    timeoutIdPrefix: 'timeoutFor_',
    productPrefix: 'product_'
})

const getters = {
    isActive: state => id => state.active.includes(id),
    globalNotifications: state => state.globals,
}

const mutations = {
    [SHOW_NOTIFICATION](state, id) {
        if (id && !state.active.includes(id)) state.active.push(id)
    },
    [REMOVE_NOTIFICATION](state, id) {
        if (id && state.active.indexOf(id) !== -1) {
            state.active.splice(state.active.indexOf(id), 1)
            // Remove the timeoutId from state.timeoutIds as this will no longer be needed
            const timeoutIdPrefix = `${state.timeoutIdPrefix}${id}`
            const timeoutId = state.timeoutIds.find(timeoutId => timeoutId.indexOf(timeoutIdPrefix) !== -1)
            if (timeoutId) state.timeoutIds.splice(state.timeoutIds.indexOf(timeoutId), 1)
        }
    },
    [ADD_NOTIFICATION_TIMEOUT_ID](state, { id, timeoutId }) {
        const formattedId = `${state.timeoutIdPrefix}${id}${timeoutId}`
        state.timeoutIds.push(formattedId)
    },
    [REMOVE_NOTIFICATION_TIMEOUT](state, id) {
        const timeoutIdPrefix = `${state.timeoutIdPrefix}${id}`
        const pendingTimeout = state.timeoutIds.find(timeoutId => timeoutId.indexOf(timeoutIdPrefix) !== -1)
        if (pendingTimeout) {
            const timeoutId = pendingTimeout.split(timeoutIdPrefix)[1]
            clearTimeout(timeoutId)
            state.timeoutIds.splice(state.timeoutIds.indexOf(pendingTimeout), 1)
        }
    },
    [ADD_GLOBAL](state, payload) {
        if (!payload.hasOwnProperty('message')) return
        state.globals.push({ ...payload, type: payload.type || '' })
    },
    [SET_NOTIFICATION_OPTIONS](state, payload) {
        if (typeof payload !== 'object') return
        state.options.push(payload)
    }
}

const actions = {
    showNotification({ commit, dispatch, state }, id) {
        commit(SHOW_NOTIFICATION, id)

        // Check if this notification is using timeouts to close and set it if so
        const options = state.options.find(option => option.id === id)
        if (options && options.useTimeout) {
            commit(REMOVE_NOTIFICATION_TIMEOUT, id)
            dispatch('addNotificationTimeout', { id, timeout: options.timeout })
        }
    },
    removeNotification({ commit }, id) {
        commit(REMOVE_NOTIFICATION, id)
    },
    removeNotificationTimeout({ commit }, id) {
        commit(REMOVE_NOTIFICATION_TIMEOUT, id)
    },
    addNotificationTimeout({ commit }, { id, timeout }) {
        const timeoutId = setTimeout(() => {
            commit(REMOVE_NOTIFICATION, id)
        }, timeout)
        commit(ADD_NOTIFICATION_TIMEOUT_ID, { id, timeoutId })
    },
    showFlash({ commit }, payload) {
        commit(ADD_GLOBAL, payload)
    },
    setNotificationOptions({ commit }, payload) {
        commit(SET_NOTIFICATION_OPTIONS, payload)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}