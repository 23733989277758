<template>
    <label class="radio inline-flex">
        <input
            v-if="isUsingVModel"
            type="radio" 
            :id="id ? id : null"
            :name="name ? name : null" 
            :value="value ? value : null"
            :checked="isChecked"
            @change="handleChange"
        >
        <input
            v-else
            type="radio" 
            :id="id ? id : null"
            :name="name ? name : null" 
            :value="value ? value : null"
            :checked="checked"
        >
        <span class="radio__mark text-xxs" :class="cssClass"></span>
    </label>
</template>

<script>
export default {
    name: 'radio',
    model: {
        prop: 'modelValue',
        event: 'change'
    },
    props: {
        modelValue: {},
        value: {
            type: String
        },
        cssClass: {
            type: String,
            default: ''
        },
        id: {
            type: String
        },
        name: {
            type: String
        },
        checked: {
            default: false
        },
    },
    computed: {
        isUsingVModel() {
            return typeof this.modelValue !== 'undefined' ? true : false
        },
        isChecked() {
            return this.modelValue == this.value
        }
    },
    methods: {
        handleChange() {
            this.$emit('change', this.value)
        }
    }
}
</script>