import {
    SET_ACTIVE_TAB
} from './_constants'

const state = () => ({
    activeTab: null
})

const getters = {
    activeTab: state => state.activeTab
}

const mutations = {
    [SET_ACTIVE_TAB] (state, { name }) {
        state.activeTab = name
    }
}

const actions = {
    setActiveTab({ commit }, payload) {
        commit(SET_ACTIVE_TAB, payload)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}