<template>
    <component :is="tag"
        class="appearance-none"
        :class="{ [activeClass]: isActive }"
        @click.prevent="handleClick"
    >
        <slot :isActive="isActive"></slot>
    </component>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'tab',
    inject: {
        injectedTabsModule: {
            from: 'tabsModule',
            default: null
        }
    },
    props: {
        contentName: {
            type: String,
            required: true
        },
        activeClass: {
            type: String,
            default: ''
        },
        tag: {
            type: String,
            default: 'button'
        },
        forTabsModule: {
            type: String,
            default: null
        }
    },
    computed: {
        ...mapState({
            tabsModule(state) {
                return this.forTabsModule ? state.modulePrefixes.tabs + this.forTabsModule : this.injectedTabsModule
            }
        }),
        isActive: {
            get() {
                return this.tabsModule ? this.$store.getters[`${this.tabsModule}/activeTab`] === this.contentName : false
            },
            set(value) {
                return value
            }
        },
        activeTabInGroup() {
            return this.tabsModule ? this.$store.getters[`${this.tabsModule}/activeTab`] : null
        }
    },
    watch: {
        activeTabInGroup(activeTabName) {
            if (activeTabName !== this.contentName) {
                this.closeTab()
            }
        }
    },
    methods: {
        handleClick() {
            if (!this.isActive) {
                this.openTab()
                this.setActiveTab(this.contentName)
            }
        },
        openTab() {
            this.isActive = true
        },
        closeTab() {
            this.isActive = false
        },
        setActiveTab(name = null) {
            if (this.tabsModule && this.$store.hasModule(this.tabsModule)) {
                this.$store.dispatch(`${this.tabsModule}/setActiveTab`, { name })
            }
        }
    }
}
</script>