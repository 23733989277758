import { mapActions } from 'vuex'

export default {
    computed: {
        hasOptions() {
            const options = this.methodData.options
            if (Array.isArray(options)) return options.length > 0
            if (typeof options === 'object' && options !== null) return Object.keys(options).length > 0
            return false
        },
        activePaymentMethodId: {
            get() {
                return this.$store.getters['checkout/activePaymentMethodId']
            },
            set(methodId) {
                this.updatePaymentMethod({ paymentMethodId: methodId })
            }
        }
    },
    methods: {
        ...mapActions('checkout', [
            'updatePaymentMethod'
        ]),
        isSelected(val) {
            return this.activePaymentMethodId === val
        }
    }
}