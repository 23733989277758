<template>
    <div v-if="state">
        <slot 
            :items="state.items"
            :refine="state.refine"
        ></slot>
    </div>
    <div
        v-else-if="!state && showSkeletonLoader" 
        class="w-full flex justify-center items-center text-center font-bold px-4 py-2.5 text-white bg-grey-blue"
    >
        <span class="is-loading">&nbsp;</span>
    </div>
</template>

<script>
import { createWidgetMixin } from "vue-instantsearch";
import { connectMenu } from "instantsearch.js/es/connectors";

export default {
    name: 'algolia-menu',
    mixins: [createWidgetMixin({ connector: connectMenu })],
    props: {
        attribute: {
            type: String,
            required: true
        },
        limit: {
            type: Number,
            default: 1000
        },
        sortBy: {
            type: Array,
            default: () => ['name:asc']
        },
        showSkeletonLoader: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        widgetParams() {
            return {
                attribute: this.attribute,
                limit: this.limit,
                sortBy: this.sortBy
            }
        },
    }
}
</script>