<template>
    <component 
        :is="computedTag" 
        @click="handleClick" 
        :href="(href && !isDisabled) ? href : null"
        class="flex flex-col items-center" 
        :class="{ 'cursor-auto': progressBarDisabled || isDisabled }"
    >
        <!-- Dot -->
        <span class="inline-block w-7 h-7 rounded-full mb-5" :class="{ 'bg-orange': isActive, 'bg-gray-200': !isActive }"></span>

        <!-- Text -->
        <span class="text-sm leading-really-tight" :class="{ 'text-orange': isActive, 'text-grey-blue': !isActive }">
            <slot></slot>
        </span>
    </component>
</template>

<script>
export default {
    name: 'progress-bar-option',
    inject: ['progressBarModule', 'progressBarDisabled'],
    props: {
        name: {
            type: [String,Number],
            required: true
        },
        href: {
            type: String,
            default: ''
        },
        tag: {
            type: String,
            default: 'button'
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    created() {
        if (this.progressBarModule) {
            this.$store.dispatch(`${this.progressBarModule}/setProgressBarOption`, { value: this.name })
        }
    },
    mounted() {
        // this is needed to set the correct width of the progress bar component
        const elWidth = this.$el?.getBoundingClientRect().width
        const halfElWidth = elWidth ? Math.round(elWidth / 2) : 0
        if (this.isFirstOption) this.setWidthOffset({ start: halfElWidth })
        else if (this.isLastOption) this.setWidthOffset({ end: halfElWidth })
    },
    computed: {
        computedTag() {
            return this.href ? 'a' : this.tag
        },
        isFirstOption() {
            return this.progressBarModule ? this.$store.getters[`${this.progressBarModule}/isFirstOption`](this.name) : null
        },
        isLastOption() {
            return this.progressBarModule ? this.$store.getters[`${this.progressBarModule}/isLastOption`](this.name) : null
        },
        activeOption() {
            return this.progressBarModule ? this.$store.getters[`${this.progressBarModule}/activeOption`] : null
        },
        activeOptionIndex() {
            if (this.progressBarModule) {
                return this.$store.getters[`${this.progressBarModule}/activeOptionIndex`]
            }
            return 0
        },
        options() {
            if (this.progressBarModule) {
                return this.$store.getters[`${this.progressBarModule}/options`]
            }
            return []
        },
        isActive() {
            const thisIndex = this.options.findIndex(option => option === this.name)
            return thisIndex <= this.activeOptionIndex ? true : false
        }
    },
    methods: {
        handleClick() {
            if (this.isDisabled) return
            if (this.progressBarModule && !this.href) {
                if (!this.progressBarDisabled) {
                    this.$store.dispatch(`${this.progressBarModule}/setActiveOption`, { value: this.name })
                }
            }
            this.$emit('option-selected', this.name)
        },
        setWidthOffset(payload) {
            if (this.progressBarModule) this.$store.dispatch(`${this.progressBarModule}/setWidthOffset`, payload)
        }
    }
}
</script>