import {
    SET_CLEAR_ALL_REFINEMENTS_FUNCTION,
    SET_HAS_PERFORMED_FIRST_SEARCH,
    SET_IS_LOADING
} from './_constants'

const state = () => ({
    isLoading: false,
    hasPerformedFirstSearch: false,
    clearAllRefinements() {}
})

const getters = {
    isLoading: state => state.isLoading,
    hasPerformedFirstSearch: state => state.hasPerformedFirstSearch,
    searchInputName: ( state, getters, rootState, rootGetters ) => rootGetters['settings/algoliaIndexName'] + '[query]'
}

const mutations = {
    [SET_CLEAR_ALL_REFINEMENTS_FUNCTION](state, func) {
        state.clearAllRefinements = func
    },
    [SET_IS_LOADING](state, val) {
        state.isLoading = val
    },
    [SET_HAS_PERFORMED_FIRST_SEARCH](state, val) {
        state.hasPerformedFirstSearch = val
    }
}

const actions = {
    setClearAllRefinementsFunction({ commit }, func) {
        commit(SET_CLEAR_ALL_REFINEMENTS_FUNCTION, func)
    },
    setLoading({ commit }, val) {
        commit(SET_IS_LOADING, val)
    },
    setHasPerformedFirstSearch({ commit }, val) {
        commit(SET_HAS_PERFORMED_FIRST_SEARCH, val)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}