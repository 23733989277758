<template>
    <label :for="id ? id : null" class="inline-block cursor-pointer">
        <!-- If component is using v-model -->
        <input
            v-if="isUsingVModel"
            type="radio"
            class="appearance-none hidden"
            :id="id ? id : null"
            :name="name ? name : null"
            :value="value ? value : null"
            :checked="isChecked"
            @change="handleChange"
        >
        <!-- If using the component without v-model (as a standard radio input for example) -->
        <input
            v-else
            type="radio"
            class="appearance-none hidden"
            :id="id ? id : null"
            :name="name ? name : null"
            :value="value ? value : null"
        >
        <!-- 'Checked' styles being set in the <style> tag below -->
        <div class="flex justify-between items-center bg-grey-blue border-grey-blue hover:bg-grey-dark hover:border-grey-dark focus:bg-grey-dark focus:border-grey-dark focus:outline-none" :class="sizings.container">
            <span v-if="$slots.default" class="grow text-center text-white font-bold pr-1" :class="sizings.text"><slot></slot></span>
            <span class="flex justify-center items-center text-orange bg-white" :class="sizings.box">
                <i class="hidden fas fa-check" :class="sizings.tick"></i>
            </span>
        </div>
    </label>
</template>

<script>
export default {
    name: 'checkable-radio',
    props: {
        id: {
            type: String
        },
        name: {
            type: String
        },
        value: {
            type: String,
            required: true
        },
        modelValue: {},
        size: {
            type: String,
            default: 'base',
            validator: str => /(sm|base|lg)/.test(str)
        }
    },
    model: {
        prop: 'modelValue',
        event: 'change'
    },
    computed: {
        isUsingVModel() {
            return typeof this.modelValue !== 'undefined' ? true : false
        },
        isChecked() {
            return this.modelValue == this.value
        },
        sizings() {
            const sizings = {
                container: 'border-4',
                box: 'w-8 h-8 min-w-8 min-h-8',
                text: 'text-sm',
                tick: 'text-base'
            }
            switch(this.size) {
                case 'sm':
                    sizings.container = 'border-3'
                    sizings.box = 'w-6 h-6 min-w-6 min-h-6'
                    sizings.text = 'text-xs pl-0.5 pr-1.5'
                    sizings.tick = 'text-sm'
                    return sizings
                case 'base':
                    return sizings
                case 'lg':
                    sizings.box = 'w-10 h-10 min-w-10 min-h-10'
                    sizings.text = 'text-lg'
                    sizings.tick = 'text-lg'
                    return sizings
                default:
                    return sizings
            }
        }
    },
    methods: {
        handleChange() {
            this.$emit('change', this.value)
        }
    }
}
</script>

<style lang="scss" scoped>
input {
    &:checked {
        & + div {
            @apply bg-orange border-orange;

            &:hover,
            &:focus {
                @apply bg-orange border-orange;
            }

            span i {
                @apply inline-block;
            }
        }
    }
}
</style>
