<template>
  <div class="absolute top-0 bottom-0 left-0 right-0 z-20 w-full bg-gray-950/50 backdrop-blur-sm" v-show="isOverlayOpen">
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'overlay',
  computed: {
    ...mapState(['isOverlayOpen'])
  }
}
</script>
