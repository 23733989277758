<template>
    <div v-if="notifications.length > 0">
        <template v-if="notification">
            <flash-notification 
                :type="notification.type" 
                :id="notification.id"
                v-bind="options"
            >{{ notification.message }}</flash-notification>
        </template>

        <!-- recursive (so we have smooth transitions and no errors with duplicates) -->
        <flash-notifications 
            v-if="filteredNotifications" 
            :notifications="filteredNotifications" 
        />
    </div>
</template>

<script>
import FlashNotification from './FlashNotification'

export default {
    name: 'flash-notifications',
    components: { FlashNotification },
    props: {
        notifications: {
            type: Array,
            required: true
        }
    },
    computed: {
        notification() {
            return this.notifications[0]
        },
        filteredNotifications() {
            const notifications = [...this.notifications]
            notifications.shift()
            return notifications
        },
        options() {
            if (!this.notification || !this.notification.hasOwnProperty('options')) return {}
            return this.notification.options
        }
    }
}
</script>