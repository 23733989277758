import {
    SET_ALGOLIA_INDEX_NAME,
    SET_ALGOLIA_APP_ID,
    SET_ALGOLIA_API_KEY,
    SET_ALGOLIA_DEFAULT_FILTERS
} from './_constants'

// TODO: Can we set all of these values in the set-initial-state.php partial?
// Object.freeze is being used below to make the value non-reactive.
const state = () => ({
    algoliaIndexName: '',
    algoliaAppId: '',
    algoliaApiKey: '',
    algoliaDefaultFilters: '',
    productPath: Object.freeze('product/view'),
    productsIndexPath: Object.freeze('product/search')
})

const getters = {
    algoliaIndexName: state => state.algoliaIndexName,
    algoliaAppId: state => state.algoliaAppId,
    algoliaApiKey: state => state.algoliaApiKey,
    algoliaDefaultFilters: state => state.algoliaDefaultFilters,
    productsIndexPath: state => state.productsIndexPath
}

const mutations = {
    [SET_ALGOLIA_INDEX_NAME](state, indexName) {
        state.algoliaIndexName = indexName
    },
    [SET_ALGOLIA_APP_ID](state, appId) {
        state.algoliaAppId = appId
    },
    [SET_ALGOLIA_API_KEY](state, apiKey) {
        state.algoliaApiKey = apiKey
    },
    [SET_ALGOLIA_DEFAULT_FILTERS](state, value) {
        state.algoliaDefaultFilters = value
    }
}

const actions = {
    setAlgoliaIndexName({ commit }, indexName) {
        commit(SET_ALGOLIA_INDEX_NAME, indexName)
    },
    setAlgoliaAppId({ commit }, appId) {
        commit(SET_ALGOLIA_APP_ID, appId)
    },
    setAlgoliaApiKey({ commit }, apiKey) {
        commit(SET_ALGOLIA_API_KEY, apiKey)
    },
    setAlgoliaDefaultFilters({ commit }, value) {
        commit(SET_ALGOLIA_DEFAULT_FILTERS, value)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
