<template>
    <ajax-add-to-cart>
        <template slot-scope="{ pending }">
            <input type="hidden" name="Product[id]" :value="productId">
            <input type="hidden" name="Product[quantity]" :value="qty">
            <button class="btn w-full" :class="{ 'is-loading is-loading--small': pending }"><slot>Add to Basket</slot></button>

            <added-to-cart-notification :product-id="productId" />
        </template>
    </ajax-add-to-cart>
</template>

<script>
import AjaxAddToCart from './AjaxAddToCart'
import AddedToCartNotification from './AddedToCartNotification'

export default {
    name: 'add-to-cart-button',
    components: {
        AjaxAddToCart,
        AddedToCartNotification
    },
    props: {
        productId: {
            type: String,
            required: true
        },
        qty: {
            type: [String, Number],
            default: '1'
        }
    }
}
</script>