<template>
    <label class="checkbox inline-flex">
        <input 
            v-if="isUsingVModel"
            type="checkbox" 
            :id="id ? id : null"
            :name="name ? name : null" 
            :value="value ? value : null"
            :checked="shouldBeChecked"
            @change="updateInput"
        >
        <input 
            v-else
            type="checkbox"
            :id="id ? id : null"
            :name="name ? name : null" 
            :value="value ? value : null"
            :checked="checked"
        >
        <span class="checkbox__mark text-xxs" :class="cssClass"></span>
    </label>
</template>

<script>
export default {
    name: 'checkbox',
    model: {
        prop: 'modelValue',
        event: 'change'
    },
    props: {
        modelValue: {},
        value: {
            type: String
        },
        cssClass: {
            type: String,
            default: ''
        },
        id: {
            type: String
        },
        name: {
            type: String
        },
        checked: {
            default: false
        },
        trueValue: {
            default: true
        },
        falseValue: {
            default: false
        }
    },
    computed: {
        isUsingVModel() {
            return typeof this.modelValue !== 'undefined' ? true : false
        },
        shouldBeChecked() {
            if (this.modelValue instanceof Array) {
                return this.modelValue.includes(this.value)
            }
            return this.modelValue === this.trueValue
        }
    },
    methods: {
        updateInput(e) {
            let isChecked = e.target.checked

            if (this.modelValue instanceof Array) {
                let newValue = [...this.modelValue]

                if (isChecked) {
                    newValue.push(this.value)
                } else {
                    newValue.splice(newValue.indexOf(this.value), 1)
                }

                this.$emit('change', newValue)
            } else {
                this.$emit('change', isChecked ? this.trueValue : this.falseValue)
            }
        }
    }
}
</script>