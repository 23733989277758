import axios from 'axios'
import qs from 'qs'

export default axios.create({
    baseURL: window.origin,
    timeout: 10000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest'
    },
    transformRequest: [
        (data, headers) => {
            // Because of the below stringify, it's required to pass the data as a javascript object, not FormData.
            return qs.stringify(data)
        }
    ]
})
