<template>
    <div class="inline-flex input input__text min-h-12" :class="fieldClasses">
        <input 
            :type="type" 
            :name="name" 
            class="w-full focus:outline-none py-px" 
            :class="inputClass" :placeholder="placeholder"
            :autocomplete="autocomplete"
            :list="list ? list: null"
            @focus="handleFocus"
            @blur="handleBlur"
        >
        <button 
            type="button" 
            class="pl-3.5 text-grey-blue focus:outline-none focus:text-grey-dark" 
            :class="dynamicClasses" 
            @click="toggleVisibility"
        >
            <i class="far fa-eye"></i>
        </button>
    </div>
</template>

<script>
export default {
    name: 'password-field',
    props: {
        name: {
            type: String,
            default: ''
        },
        inputClass: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        autocomplete: {
            type: String,
            default: 'on'
        },
        list: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            type: 'password',
            fieldClasses: ''
        }
    },
    methods: {
        toggleVisibility() {
            this.type = (this.type === 'password') ? 'text' : 'password'
        },
        handleFocus() {
            this.fieldClasses = 'border-orange'
        },
        handleBlur() {
            this.fieldClasses = ''
        }
    },
    computed: {
        dynamicClasses() {
            return (this.type !== 'password') ? 'text-orange focus:text-orange-dark' : ''
        }
    }
}
</script>