import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth/auth'
import settings from './modules/settings/settings'
// import darkMode from './modules/darkMode/darkMode'
import priceHider from './modules/priceHider/priceHider'
import layout from './modules/layout/layout'
import notifications from './modules/notifications/notifications'
import feedback from './modules/feedback/feedback'
import cart from './modules/cart/cart'
import search from './modules/search/search'
import stockLevel from './modules/stockLevel/stockLevel'
import dynamicImport from './modules/dynamicImport/dynamicImport'
import {
    TOGGLE_OVERLAY
} from './constants'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const modulePrefixes = {
    accordion: 'accordionGroup_',
    tabs: 'tabs_',
    progressBar: 'progressBar_',
    addresses: 'addresses_',
    slideoutForm: 'slideout_form_',
    featuredImage: 'featured_image_'
}

export default new Vuex.Store({
    modules: {
        auth,
        settings,
        // darkMode,
        priceHider,
        layout,
        notifications,
        feedback,
        cart,
        search,
        stockLevel,
        dynamicImport
    },
    state: {
        modulePrefixes,
        isOverlayOpen: false
    },
    mutations: {
        [TOGGLE_OVERLAY](state, { isOpen }) {
            state.isOverlayOpen = isOpen
        }
    },
    actions: {
        setInitialState({ dispatch }, payload) {
            if (payload.hasOwnProperty('algolia')) {
                dispatch('settings/setAlgoliaIndexName', Object.freeze(payload.algolia?.indexName))
                dispatch('settings/setAlgoliaAppId', Object.freeze(payload.algolia?.appId))
                dispatch('settings/setAlgoliaApiKey', Object.freeze(payload.algolia?.apiKey))
                dispatch('settings/setAlgoliaDefaultFilters', Object.freeze(payload.algolia?.defaultFilters))
            }
            if (payload.hasOwnProperty('cart')) {
                dispatch('cart/setTotalItems', payload.cart?.totalItems ?? 0)
                dispatch('cart/setSubTotal', payload.cart?.subTotal ?? 0)
                dispatch('cart/setVATPercents', payload.cart?.vat ?? {})
                dispatch('cart/setTotalPrice', payload.cart?.total ?? 0)
                dispatch('cart/setLineItems', payload.cart?.lineItems ?? [])
            }
            if (payload.hasOwnProperty('isAuth')) {
                dispatch('auth/setIsAuth', payload.isAuth ?? false)
            }
            // Added this to very quickly sort the quick buy button authentication on the kiosk mode homepage.
            if (payload.hasOwnProperty('isGuest')) {
                dispatch('auth/setIsGuest', payload.isGuest ?? true)
            }
        }
    },
    strict: debug
})