var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasOptions)?_c('payment-type',{attrs:{"heading":_vm.methodData.heading,"type":_vm.methodData.type,"has-error":_vm.hasError}},[_c('div',{staticClass:"flex flex-col md:flex-row text-sm text-grey-blue pr-16"},[_c('div',{staticClass:"grid gap-3.5 md:mr-8 credit-info"},[_c('ul',{staticClass:"col-span-full sm:col-span-1"},[_c('li',{staticClass:"font-bold"},[_vm._v("Credit Limit: "+_vm._s(_vm.formattedLimit))]),_c('li',[_vm._v("Credit Term: "+_vm._s(_vm.term)+" Days")])]),_c('ul',{staticClass:"col-span-full sm:col-span-1 sm:justify-self-end md:justify-self-start"},[_c('li',{staticClass:"flex items-center"},[_c('span',{staticClass:"w-3.5 h-3.5 mr-1 flex-shrink-0",class:{
            'bg-orange': !_vm.isOnStop,
            'bg-danger': _vm.isOnStop
          }}),_c('span',{class:{ 'text-danger': _vm.isOnStop }},[_vm._v(_vm._s(_vm.formattedOutstanding)+" Still To Be Paid")])]),_c('li',{staticClass:"flex items-center"},[_c('span',{staticClass:"w-3.5 h-3.5 mr-1 flex-shrink-0",class:{
            'bg-orange-lighter': !_vm.isOnStop,
            'bg-pink': _vm.isOnStop
          }}),_c('span',{class:{ 'text-danger': _vm.isOnStop }},[_vm._v(_vm._s(_vm.formattedOrderTotal)+" For Current Order")])])])]),_c('div',{staticClass:"flex flex-col grow pt-4 md:pt-0.5"},[_c('span',{staticClass:"bg-grey-pale relative h-3.5"},[_c('span',{staticClass:"flex absolute left-0 top-0 w-full h-full",class:{ 'bg-danger': _vm.isExceedingLimit }},[(!_vm.isExceedingLimit)?_c('span',{staticClass:"inline-block h-full",class:{
            'bg-orange': !_vm.isOnStop,
            'bg-danger': _vm.isOnStop
          },style:(_vm.percentageWidths.outstanding)}):_vm._e(),(!_vm.isExceedingLimit)?_c('span',{staticClass:"inline-block h-full",class:{
            'bg-orange-lighter': !_vm.isOnStop,
            'bg-pink': _vm.isOnStop
          },style:(_vm.percentageWidths.orderTotal)}):_vm._e()])]),_c('div',{staticClass:"flex justify-between mt-1"},[_c('span',[_vm._v("£0")]),_c('span',[_vm._v(_vm._s(_vm.formattedLimit))])])])]),(_vm.hasError)?_c('div',{staticClass:"flex items-baseline text-danger text-sm mt-3"},[_c('span',{staticClass:"flex-shrink-0 text-xxxs border-2 border-danger rounded-full w-5 h-5 flex justify-center items-center mr-2.5"},[_c('i',{staticClass:"fas fa-info"})]),(_vm.isOnStop)?_c('p',[_vm._v("Your account is currently on stop.")]):(_vm.isExceedingLimit)?_c('p',[_vm._v("You will exceed your credit limit with this order.")]):_vm._e()]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }