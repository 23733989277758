<template>
  <!-- <div class="relative z-10 wrapper" v-click-outside="vcoConfig"> -->
  <div class="relative z-10 wrapper">
    <!-- Trigger -->
    <button
      class="trigger absolute top-0 left-1/2 -translate-x-1/2 flex items-center bg-grey-blue shadow-box-small text-sm text-grey-pale px-3 pt-2.5 pb-1.5 uppercase focus:outline-none z-30"
      type="button" @click="handleClick">
      Find it fast <span class="ml-2 chevron-select" :class="{ 'chevron-select--active': isOpen }"><i
          class="fas fa-chevron-down"></i></span>
    </button>

    <!-- Panel -->
    <div v-show="isOpen" class="absolute top-0 left-0 w-full bg-gray-950">
      <slot>
        <div class="container max-w-5xl pt-12 pb-9">
          <h3 class="text-white text-2xl uppercase pb-1.5 border-b-2 border-white mb-6.5">Find It Fast</h3>
          <template v-if="isAuth">
            <algolia-connect-find-it-fast v-if="connectWithAlgolia" />
          </template>
          <p v-else class="text-white">Find it fast filters are only available for logged in users with an activated trade
            account.</p>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import AlgoliaConnectFindItFast from './AlgoliaConnectFindItFast'
import { mapGetters } from 'vuex'

export default {
  name: 'find-it-fast',
  components: {
    AlgoliaConnectFindItFast
  },
  data() {
    return {
      isOpen: false,
      connectWithAlgolia: false,
      vcoConfig: {
        handler: this.handleClick,
        middleware: this.clickOutsideMiddleware
      },
    }
  },
  computed: {
    ...mapGetters('auth', [
      'isAuth'
    ])
  },
  methods: {
    clickOutsideMiddleware() {
      return this.isOpen
    },
    handleClick() {
      this.isOpen = !this.isOpen
      this.connectWithAlgolia = true
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  &:before {
    content: '';
    @apply absolute top-0 left-0 w-full h-2.5 bg-grey-blue z-40;
  }

  &:after {
    content: '';
    @apply absolute top-2.5 left-0 w-full h-2.5 bg-grey-dark z-20;
  }
}
</style>
