<template>
    <ul 
        v-if="hasErrors"
        class="list-inside bg-red-100 text-red-900 px-3.5 py-1.5"
        :class="{ 
            'border border-red-900': withBorder,
            'list-disc': showAsList
        }"
    >
        <li v-for="error in errors" :key="error">{{ error }}</li>
        <li v-if="hasSlotData"><slot></slot></li>
    </ul>
</template>

<script>
export default {
    name: 'error-list',
    props: {
        errors: {
            type: Array, // Array<string>
            default: () => []
        },
        withBorder: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasErrors() {
            return this.errors.length && this.errors.find(error => error)
        },
        showAsList() {
            return this.errors.length > 1 ? true : false
        },
        hasSlotData() {
            return !!this.$slots.default
        }
    }
}
</script>