<template>
    <button v-on="listeners" class="bg-grey-blue rounded-full w-6.5 h-6.5 sm:w-11 sm:h-11 text-white sm:text-xl"><i class="fas fa-times"></i></button>
</template>

<script>
export default {
    name: 'lightbox-close-button',
    computed: {
        listeners() {
            return this.$listeners
        }
    }
}
</script>