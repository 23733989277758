import {
    CHANGE_ADDED_TO_CART_FEEDBACK_METHOD,
    LIGHTBOX,
    NOTIFICATION
} from './_constants';

const lsKey = 'added-to-cart-feedback-method'

// E.g: feedbackMethod = { name: (LIGHTBOX | NOTIFICATION); expiryTime: number (UTC time in milliseconds); }
let feedbackMethod = localStorage.getItem(lsKey) ? JSON.parse(localStorage.getItem(lsKey)) : { name: LIGHTBOX, expiryTime: 0}

const state = () => ({
    feedbackMethod
})

const getters = {
    addedToCartFeedbackMethod: ({ feedbackMethod }) => {
        const hasNotExpired = (feedbackMethod.expiryTime - new Date().getTime()) > 0
        if (feedbackMethod.name === NOTIFICATION) {
            return hasNotExpired ? feedbackMethod.name : LIGHTBOX
        } else {
            return feedbackMethod.name
        }
    }
}

const mutations = {
    [CHANGE_ADDED_TO_CART_FEEDBACK_METHOD](state, feedbackMethod) {
        const oneDayFromNow = new Date().getTime() + (24 * 60 * 60 * 1000)
        const selectedMethod = {
            name: feedbackMethod,
            expiryTime: oneDayFromNow
        }
        state.feedbackMethod = selectedMethod
        localStorage.setItem(lsKey, JSON.stringify(selectedMethod))
    }
}

const actions = {
    changeAddedToCartFeedbackMethod({ commit }, feedbackMethod) {
        commit(CHANGE_ADDED_TO_CART_FEEDBACK_METHOD, feedbackMethod)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}