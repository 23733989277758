<template>
    <button
        type="button" 
        class="mega-menu-trigger inline-block focus:outline-none"
        :class="{ [activeClass]: isActive }"
        @click="handleClick"
        data-mega-menu-trigger
    >
        <slot></slot>
    </button>
</template>

<script>
import { mapGetters } from 'vuex'
import megaMenuModule from '../store/modules/megaMenu/megaMenu'

export default {
    name: 'mega-menu-trigger',
    props: {
        menuName: {
            type: String,
            required: true
        },
        activeClass: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters('megaMenu', [ 
            'nameOfMenuOpen'
        ]),
        isActive() {
            return this.menuName === this.nameOfMenuOpen
        }
    },
    methods: {
        handleClick() {
            const shouldOpenMenu = this.nameOfMenuOpen !== this.menuName
            if (!this.$store.hasModule('megaMenu')) {
                this.$store.registerModule('megaMenu', megaMenuModule)
                this.$store.dispatch('dynamicImport/load', 'megaMenu')
            }
            this.$store.dispatch('megaMenu/toggleMegaMenu', {
                name: shouldOpenMenu ? this.menuName : '',
                isOverlayOpen: shouldOpenMenu ? true : false
            })
        }
    }
}
</script>