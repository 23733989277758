<template>
    <ajax-add-to-cart>
        <template slot-scope="{ pending }">
            <input type="hidden" name="Product[id]" :value="productId">
            <input type="number" name="Product[quantity]" v-model="qty" class="input input__qty" :class="inputClass">
            <button class="btn" :class="[btnClass, { 'is-loading is-loading--small': pending }]"><slot>Add to Basket</slot></button>

            <added-to-cart-notification :product-id="productId" />
        </template>
    </ajax-add-to-cart>
</template>

<script>
import AjaxAddToCart from './AjaxAddToCart'
import AddedToCartNotification from './AddedToCartNotification'

export default {
    name: 'simple-add-to-cart-form',
    components: {
        AjaxAddToCart,
        AddedToCartNotification
    },
    props: {
        productId: {
            type: [String, Number],
            required: true
        },
        inputClass: {
            type: String,
            default: ''
        },
        btnClass: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            qty: 1
        }
    }
}
</script>
