<template>
    <div>
        <slot :activeTab="activeTab"></slot>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import tabsModule from '../store/modules/tabs/tabs'
const { state, getters, mutations, actions } = tabsModule

export default {
    name: 'tabs',
    provide() {
        return {
            tabsModule: this.moduleName
        }
    },
    props: {
        defaultActive: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            moduleID: this.name ? this.name : this._uid
        }
    },
    computed: {
        ...mapState({
            moduleName(state) {
                return state.modulePrefixes.tabs + this.moduleID
            }
        }),
        activeTab() {
            return this.$store.getters[`${this.moduleName}/activeTab`]
        }
    },
    created() {
        if (!this.$store.hasModule(this.moduleName)) {
            this.$store.registerModule(this.moduleName, {
                namespaced: true,
                state,
                getters,
                mutations,
                actions
            })
        }

        if (this.defaultActive) {
            this.$store.dispatch(`${this.moduleName}/setActiveTab`, { name: this.defaultActive })
        }
    },
    watch: {
        activeTab(val, oldVal) {
            if (val && val !== oldVal) this.$emit('changed')
        }
    }
}
</script>