<template>
  <div class="relative">
    <div class="h-2.5 bg-grey-pale absolute top-3.5 -translate-y-1/2" :style="parentBarStyles">
      <span class="absolute top-0 left-0 h-full bg-orange-dark" :style="progressBarWidth"></span>
    </div>
    <div class="relative flex justify-between">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import progressBarModule from '../store/modules/progressBar/progressBar'
const { state, getters, mutations, actions } = progressBarModule

export default {
  name: 'progress-bar',
  provide() {
    return {
      progressBarModule: this.moduleName,
      progressBarDisabled: this.disabled
    }
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    defaultOption: {
      type: [String, Number],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      moduleID: this.name ? this.name : this._uid
    }
  },
  computed: {
    ...mapState({
      moduleName(state) {
        return state.modulePrefixes.progressBar + this.moduleID
      }
    }),
    options() {
      return this.$store.getters[`${this.moduleName}/options`]
    },
    activeOption() {
      return this.$store.getters[`${this.moduleName}/activeOption`]
    },
    activeOptionIndex() {
      return this.$store.getters[`${this.moduleName}/activeOptionIndex`]
    },
    progressBarWidth() {
      const width = this.$store.getters[`${this.moduleName}/percentageComplete`]
      return {
        width: `${width}%`
      }
    },
    parentBarStyles() {
      const widthOffset = this.$store.getters[`${this.moduleName}/totalWidthOffset`]
      const leftOffset = this.$store.state[`${this.moduleName}`].widthOffset.start
      return {
        width: `calc(100% - ${widthOffset}px)`,
        left: `${leftOffset}px`
      }
    }
  },
  created() {
    if (!this.$store.hasModule(this.moduleName)) {
      this.$store.registerModule(this.moduleName, {
        namespaced: true,
        state,
        getters,
        mutations,
        actions
      })
    }

    if (this.defaultOption) {
      this.$store.dispatch(`${this.moduleName}/setActiveOption`, { value: this.defaultOption })
    }
  }
}
</script>
