<template>
    <button 
        type="button" 
        aria-label="Main menu" 
        tabindex="0" 
        class="text-2xl"
        data-burger-menu
        :aria-expanded="ariaExpanded"
        @click="handleClick"
    >
        <slot :isOpen="isOpen"></slot>
    </button>
</template>

<script>
import mobileMenu from '../store/modules/mobileMenu/mobileMenu'

export default {
    name: "mobile-menu-toggle",
    computed: {
        // Setting the getter this way as the mobileMenu mobile is conditionally created
        isOpen() {
            if (this.$store.hasModule('mobileMenu')) return this.$store.getters['mobileMenu/isOpen']
            return false
        },
        ariaExpanded() {
            return this.isOpen ? 'true' : 'false'
        }
    },
    methods: {
        handleClick() {
            if (!this.$store.hasModule('mobileMenu')) {
                this.$store.registerModule('mobileMenu', mobileMenu)
                this.$store.dispatch('dynamicImport/load', 'mobileMenu')
            }
            this.$store.dispatch('mobileMenu/toggleMobileMenu')
        }
    }
};
</script>